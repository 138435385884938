import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Chip,
  Divider,
} from "@mui/material";
import Bar from "../Components/Bar";
import { labels, unixToLocaleDate } from "../labels";
import { useEffect, useMemo, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { AIQuestionType, entries } from "../state";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";
import { unparse } from "papaparse";
import { saveAs } from "file-saver";

function AI() {
  const { dashboardState, updateDashboard, emitNewData, mentionsState } = useWsContext();
  const { user } = useAuth0();
  const [localQuestions, setLocalQuestions] = useState<AIQuestionType[]>([]);
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState<AIQuestionType>({
    label: "",
    question: "",
    mitigation: "",
    link: "",
    category: "",
    last_update: Date.now(),
    last_updated_by: user?.email,
  });
  const [inEditMode, setInEditMode] = useState(-1);
  const { stringToColor } = useClientContext();

  const narrativeCount = useMemo(() => {
    const narrativeCounter: {
      [key: string]: { severity: { critical: number; high: number; medium: number; low: number }; hidden: number; flagged: number; total: number };
    } = {};
    mentionsState?.forEach((mention) => {
      if (!mention.ai_filter) return;
      mention.ai_filter.results?.forEach((filter) => {
        narrativeCounter[filter.key] ??= { severity: { critical: 0, high: 0, medium: 0, low: 0 }, hidden: 0, flagged: 0, total: 0 };
        mention.manual_severity && narrativeCounter[filter.key].severity[mention.manual_severity as "low"]++;
        mention.hidden && narrativeCounter[filter.key].hidden++;
        mention.flagged && narrativeCounter[filter.key].flagged++;
        narrativeCounter[filter.key].total++;
      });
    });
    return narrativeCounter;
  }, [mentionsState]);

  console.log({ narrativeCount });

  const handleEditQuestion = async (index: number) => {
    const updatedQuestions = localQuestions.map((question, i) =>
      i === index ? { ...question, last_update: Date.now(), last_updated_by: user?.email } : question
    );
    setLocalQuestions(updatedQuestions);
    setInEditMode(-1);
    updateDashboard({ ai_questions: [...updatedQuestions].reverse() });
    emitNewData({
      action: "setAIQuestion",
      questionIndex: (localQuestions.length - 1 - index).toString(),
      question: updatedQuestions[index],
    });
  };

  const handleDeleteQuestion = async (index: number) => {
    const updatedQuestions = localQuestions.filter((_, i) => i !== index);
    setLocalQuestions(updatedQuestions);
    updateDashboard({ ai_questions: updatedQuestions.reverse() });
    setInEditMode(-1);
    emitNewData({
      action: "removeAIQuestion",
      questionIndex: (localQuestions.length - 1 - index).toString(),
    });
  };

  const handleAddQuestion = async () => {
    if (!newQuestion.label || !newQuestion.question) return;
    const updatedQuestions = [newQuestion, ...localQuestions];
    setLocalQuestions(updatedQuestions);
    updateDashboard({ ai_questions: updatedQuestions.reverse() });
    setNewQuestion((prev) => ({ ...prev, label: "", question: "", mitigation: "", link: "", category: "" }));
    setInEditMode(-1);
    emitNewData({ action: "setAIQuestion", question: newQuestion });
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    setLocalQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
      return updatedQuestions;
    });
  };

  const categories = useMemo(() => {
    return Array.from(new Set((dashboardState.ai_questions || []).map((q) => q.category).filter(Boolean)));
  }, [dashboardState.ai_questions]);

  const exportToCSV = () => {
    const csvData = dashboardState.ai_questions!.map((q) => ({
      Label: q.label,
      Question: q.question,
      Category: q.category,
      Last_Update: q.last_update,
      Last_Updated_By: q.last_updated_by,
      Link: q.link,
      Mitigation: q.mitigation,
    }));

    const csvString = unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "narratives.csv");
  };

  useEffect(() => {
    if (dashboardState.ai_questions) {
      setLocalQuestions([...dashboardState.ai_questions].reverse() || []);
    }
  }, [dashboardState.ai_questions]);

  return (
    <div>
      <Bar label={`${labels.ai.title} | ${labels.ai.subtitle}`} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Box display="flex" justifyContent="space-between" sx={{ mb: GlobalSizes.gap }}>
          <Button
            variant="contained"
            color={addingQuestion ? "secondary" : "primary"}
            onClick={() => {
              if (addingQuestion) {
                setNewQuestion((prev) => ({ ...prev, label: "", question: "", mitigation: "", link: "" }));
              }
              setAddingQuestion(!addingQuestion);
            }}
          >
            {addingQuestion ? "Cancel" : `Add ${labels.ai.single_item}`}
          </Button>
          {localQuestions.length > 0 && (
            <Button variant="outlined" color="primary" onClick={exportToCSV}>
              Export to CSV
            </Button>
          )}
        </Box>
        {addingQuestion && (
          <Card sx={{ mb: GlobalSizes.gap, position: "relative" }}>
            <CardContent>
              <Box display="flex" justifyContent="flex-end" width={GlobalSizes.fullSize} mb={GlobalSizes.smallGap}>
                <Tooltip title="Save" placement="top">
                  <IconButton size="small" onClick={handleAddQuestion}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextField
                placeholder={`Enter ${labels.ai.single_item} Label`}
                value={newQuestion.label}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(event) => {
                  setNewQuestion((prev) => ({ ...prev, label: event.target.value }));
                }}
              />
              <TextField
                placeholder={`Enter ${labels.ai.single_item}`}
                value={newQuestion.question}
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={(event) => {
                  setNewQuestion((prev) => ({ ...prev, question: event.target.value }));
                }}
                sx={{ mt: GlobalSizes.smallGap }}
              />
              <TextField
                placeholder="Enter Mitigating content"
                value={newQuestion.mitigation}
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={(event) => {
                  setNewQuestion((prev) => ({ ...prev, mitigation: event.target.value }));
                }}
                sx={{ mt: GlobalSizes.smallGap }}
              />
              <TextField
                placeholder="Enter Link"
                value={newQuestion.link}
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={(event) => {
                  setNewQuestion((prev) => ({ ...prev, link: event.target.value }));
                }}
                sx={{ mt: GlobalSizes.smallGap }}
              />
              <FormControl fullWidth sx={{ mb: GlobalSizes.gap }}>
                <Autocomplete
                  freeSolo
                  disablePortal
                  id="combo-box-demo"
                  options={categories}
                  renderInput={(params) => <TextField {...params} placeholder="Enter Category" />}
                  value={newQuestion.category}
                  onInputChange={(_, newValue) => setNewQuestion((prev) => ({ ...prev, category: newValue }))}
                  sx={{ mt: GlobalSizes.smallGap }}
                />
              </FormControl>
            </CardContent>
          </Card>
        )}
        {localQuestions.map((question, index) => (
          <Card key={index} sx={{ mb: GlobalSizes.gap, position: "relative" }}>
            <CardContent sx={{ mb: GlobalSizes.gap }}>
              <Box position="absolute" top={GlobalSizes.smallGap} right={GlobalSizes.smallGap} mt={GlobalSizes.smallGap} mr={GlobalSizes.smallGap}>
                {inEditMode === index ? (
                  <Stack direction="row">
                    {JSON.stringify(dashboardState.ai_questions?.[localQuestions.length - 1 - index]) !== JSON.stringify(localQuestions[index]) &&
                      (console.log(dashboardState.ai_questions?.[index]),
                      console.log(localQuestions.length - 1 - index),
                      (
                        <Tooltip title="Save" placement="top">
                          <IconButton size="small" onClick={() => handleEditQuestion(index)}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ))}
                    <Tooltip title="Cancel" placement="top">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setInEditMode(-1);
                          setLocalQuestions([...(dashboardState.ai_questions || [])].reverse());
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <IconButton size="small" onClick={() => handleDeleteQuestion(index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  <Tooltip title="Edit" placement="top">
                    <IconButton size="small" onClick={() => setInEditMode(index)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              {inEditMode === index ? (
                <>
                  <TextField
                    value={question.label}
                    label="Label"
                    variant="standard"
                    onChange={(event) => handleInputChange(index, "label", event.target.value)}
                    sx={{ minWidth: GlobalSizes.midSize }}
                  />
                  <TextField
                    value={question.question}
                    label={labels.ai.single_item}
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    onChange={(event) => handleInputChange(index, "question", event.target.value)}
                    sx={{ mt: GlobalSizes.gap }}
                  />
                  <TextField
                    value={question.mitigation || ""}
                    label="Counter-Narrative"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    onChange={(event) => handleInputChange(index, "mitigation", event.target.value)}
                    sx={{ mt: GlobalSizes.gap }}
                  />
                  <TextField
                    value={question.link || ""}
                    label="Validating Link"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(event) => handleInputChange(index, "link", event.target.value)}
                    sx={{ my: GlobalSizes.gap }}
                  />
                  <FormControl fullWidth sx={{ mb: GlobalSizes.gap }}>
                    <Autocomplete
                      freeSolo
                      disablePortal
                      id="combo-box-demo"
                      options={categories}
                      renderInput={(params) => <TextField {...params} label="Category" />}
                      value={question.category}
                      onInputChange={(_, newValue) => handleInputChange(index, "category", newValue)}
                      sx={{ mt: GlobalSizes.smallGap }}
                    />
                  </FormControl>
                </>
              ) : (
                <>
                  {" "}
                  <Typography variant="h5" component="div" mb={GlobalSizes.smallGap}>
                    <Chip
                      label={`${question.label}`}
                      sx={{
                        backgroundColor: stringToColor(question.label),
                        color: "black",
                        fontSize: GlobalSizes.bigFontSize,
                        height: GlobalSizes.bigChipHeight,
                        padding: GlobalSizes.buttonPadding,
                      }}
                    />
                  </Typography>
                  <Typography variant="body2" mb={GlobalSizes.smallGap} sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                    {question.question}
                  </Typography>
                  {question.mitigation && (
                    <Typography color="GrayText" variant="body2" mb={GlobalSizes.smallGap}>
                      {question.mitigation}
                    </Typography>
                  )}
                  {question.link && (
                    <Link variant="body2" mb={GlobalSizes.smallGap}>
                      {question.link}
                    </Link>
                  )}
                  {question.category && (
                    <Typography variant="body2" my={GlobalSizes.smallGap}>
                      Category: {question.category}
                    </Typography>
                  )}
                </>
              )}
            </CardContent>
            <CardActions sx={{ position: "absolute", bottom: GlobalSizes.smallGap, right: GlobalSizes.smallGap }}>
              <Tooltip title="Alert stats">
                <Typography variant="caption" color="text.primary">
                  {entries(narrativeCount[question.label]).map(([key, val]) => {
                    if (key === "severity")
                      return entries(val).map(([severity, count]) => {
                        return !!count && `${severity}:${count} `;
                      });
                    return !!val && `${key}:${val} `;
                  })}
                </Typography>
              </Tooltip>
              <Divider />
              {question.last_update && question.last_updated_by && (
                <Tooltip title="Last edit">
                  <Typography variant="caption" color="text.secondary">
                    {`${question.last_updated_by} | ${unixToLocaleDate(question.last_update)}`}
                  </Typography>
                </Tooltip>
              )}
            </CardActions>
          </Card>
        ))}
      </Container>
    </div>
  );
}

export default AI;
