import { User } from "@auth0/auth0-react";
import mixpanel from "mixpanel-browser";
import { getTeam } from "./team-util";

const MIXPANEL_TOKEN = "02b7a9c7b644917b4b7a1e6ec815c106";

const Mixpanel = {
  track: (event: string, properties?: Record<string, unknown>): void => {
    const chosenTeam = getTeam();
    if (chosenTeam && properties) {
      mixpanel.set_group("company_id", chosenTeam);
    }
    mixpanel.track(event, properties);
  },
  user: (user: User): void => {
    mixpanel.init(MIXPANEL_TOKEN, {
      track_pageview: true,
      persistence: "localStorage",
      ignore_dnt: true,
      api_host: `https://nd74aect4fakcvoqtpntazanim0delik.lambda-url.us-east-1.on.aws/mixpanel`,
      // @ts-ignore
      record_sessions_percent: 100,
    });
    mixpanel.people.set({
      unique_id: user.email,
      name: user.name,
      $email: user.email,
      created: user.created_at,
      last_login: user.last_login,
      email_verified: user.email_verified,
      $avatar: user.picture,
      nickname: user.nickname,
      logins_count: user.logins_count,
    });
    mixpanel.identify(user.email);
  },
};

export default Mixpanel;
