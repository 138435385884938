import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import { labels } from "../labels";

function RefreshButton({ label }: { label?: string }) {
  return (
    <Button onClick={() => window.location.reload()} aria-label="refresh">
      <RefreshIcon /> {label || labels.common.indexing}
    </Button>
  );
}

export default RefreshButton;
