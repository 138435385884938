import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GlobalSizes, MitigationButtonSizes } from "../../size";
import { MitigationKeys } from "../../Pages/Action";
import QueueIcon from "@mui/icons-material/Queue";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { MITIGATIONS } from "../../labels";

interface MitigationButtonProps {
  mitigation: MitigationKeys;
  isLoading: string;
  handleMitigation: (mitigation: MitigationKeys) => Promise<void>;
  mitigationText?: string;
}

function MitigationButton(props: MitigationButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoading, mitigation, handleMitigation, mitigationText } = props;

  function copyMitigationText() {
    if (!mitigationText) return;
    navigator.clipboard.writeText(mitigationText);
  }

  const mitigationDescription = MITIGATIONS[mitigation].mitigationDescription && (
    <Typography variant="caption" sx={{ fontSize: MitigationButtonSizes.descriptionFontSize, textAlign: "left" }}>
      {MITIGATIONS[mitigation].mitigationDescription}
    </Typography>
  );
  return (
    <Stack marginTop={GlobalSizes.gap}>
      <Dialog
        sx={{ alignSelf: "center", height: MitigationButtonSizes.dialogHeight }}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
      >
        <DialogTitle id="customized-dialog-title">{mitigation.toUpperCase()} Mitigation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setIsModalOpen(false)}
          sx={{
            position: "absolute",
            right: MitigationButtonSizes.closeButtonOffset,
            top: MitigationButtonSizes.closeButtonOffset,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
            {mitigationText}
          </Typography>
          <Typography gutterBottom>
            {MITIGATIONS[mitigation].emails.length > 0 ? `Suggested emails for submission: ${MITIGATIONS[mitigation].emails.join(", ")}` : ""}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => copyMitigationText()}>
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
      {mitigationText ? (
        <ButtonGroup size="large" variant="contained" disabled={isLoading === "loading"}>
          <Tooltip title="Show request" placement="top">
            <Button
              sx={{ borderTopRightRadius: GlobalSizes.none, borderBottomRightRadius: GlobalSizes.none, borderRight: "black solid", alignItems: "start" }}
              disabled={isLoading === "loading"}
              onClick={() => setIsModalOpen(true)}
            >
              <Box style={{ display: "flex", flexDirection: "column", alignItems: "start" , whiteSpace: "nowrap"}}>
                {mitigation}
                {mitigationDescription}
              </Box>
            </Button>
          </Tooltip>
          <Tooltip title="Generate New request" placement="top">
            <Button
              sx={{ borderTopLeftRadius: GlobalSizes.none, borderBottomLeftRadius: GlobalSizes.none }}
              disabled={isLoading === "loading"}
              onClick={() => handleMitigation(mitigation)}
            >
              <QueueIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      ) : (
        <Button
          variant="contained"
          size="large"
          disabled={isLoading === "loading"}
          onClick={() => handleMitigation(mitigation)}
          sx={{ display: "flex", flexDirection: "column", alignItems: "start", whiteSpace: "nowrap" }}
        >
          {mitigation}
          {mitigationDescription}
        </Button>
      )}
    </Stack>
  );
}

export default MitigationButton;
