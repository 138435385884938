import { Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ContactDetailsProps {
  items?: string[];
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  title?: string;
}

function ContactDetails({ items, Icon, title }: ContactDetailsProps) {
  return (
    <Grid item xs={6}>
      <Typography variant="h6" pb={GlobalSizes.buttonPadding} color="primary">
        {Icon && <Icon fontSize="small" sx={{ mr: GlobalSizes.smallGap }} />}
        {title}:
      </Typography>
      {!items || items.length === 0 ? (
        <Typography>No data found</Typography>
      ) : (
        <>
          {items?.map((item, index) => (
            <Typography key={index} mb={GlobalSizes.smallGap} pl={GlobalSizes.gap}>
              • {item}
            </Typography>
          ))}
        </>
      )}
    </Grid>
  );
}

export default ContactDetails;
