import { Avatar, Box, CardHeader, Typography } from "@mui/material";
import { FC } from "react";
import { GlobalSizes } from "../../../size";
import { TwitterUserType } from "../../../state";

interface TweetCardHeaderProps {
  twitterUserType: TwitterUserType;
}

const verifiedIcons: Record<string, string> = {
  blue: "verified-blue-icon.png",
  government: "verified-gov-icon.png",
  business: "verified-business-icon.png",
};

const TweetCardHeader: FC<TweetCardHeaderProps> = ({ twitterUserType }) => {
  const verifiedIconSrc = verifiedIcons[twitterUserType.verified_type];

  return (
    <CardHeader
      avatar={<Avatar alt={twitterUserType.name} src={twitterUserType.profile_image_url} sx={{ width: GlobalSizes.fullSize, height: GlobalSizes.fullSize }} />}
      title={
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontWeight="bold" color="rgb(15, 20, 25)">
            {twitterUserType.name}
          </Typography>
          {twitterUserType.verified_type && verifiedIconSrc && (
            <img src={verifiedIconSrc} alt={`Verified ${twitterUserType.verified_type}`} className="verified_icon" />
          )}
        </Box>
      }
      subheader={
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" sx={{ color: "rgb(83, 100, 113)" }}>
            @{twitterUserType.username}
          </Typography>
          <span className="dot_separator">·</span>
          <span className="follow_button">Follow</span>
        </Box>
      }
      titleTypographyProps={{ fontWeight: "bold", color: "black" }}
    />
  );
};

export default TweetCardHeader;
