import { AiFilterType, AiResultType, MentionType } from "../state";
import {
  Stack,
  Container,
  Button,
  CircularProgress,
  Typography,
  Avatar,
  Chip,
  Badge,
  Box,
  Tooltip,
  SelectChangeEvent,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { labels, severity, SOCIAL_ICONS } from "../labels";
import { useCallback, useEffect, useState, useMemo } from "react";
import { GlobalSizes } from "../size";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridInitialState,
  GridListColDef,
  gridFilteredSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";
import List from "@mui/icons-material/List";
import GridOnIcon from "@mui/icons-material/GridOn";
import PersonIcon from "@mui/icons-material/Person";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { Link } from "react-router-dom";
import CustomTableViews from "../Components/monitoring/CustomTableViews";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SeverityChip from "../Components/SeverityChip";
import RefreshAiFilter from "../Components/investigation/ai-filter/RefreshAiFilter";
import LinearProgress from "@mui/material/LinearProgress";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { getViews, setViews } from "../team-util";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";
import MentionFocus from "../Components/MentionFocus";
import debounce from "lodash.debounce";
import ConnectedUsers from "../Components/ConnectedUsers";
import { similarityThreshold } from "../labels";

const initialGridState: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      refresh_ai_filter: false,
      ai_filter_beta: false,
      takedown_status: false,
    },
  },
  filter: {
    filterModel: {
      items: [{ field: "status", operator: "not", value: "hidden" }],
    },
  },
  sorting: {
    sortModel: [{ field: "detection_date", sort: "desc" }],
  },
  pagination: {
    paginationModel: { pageSize: 100, page: 0 },
  },
  density: "standard",
};

function LinearLoadingOverlay() {
  const { dashboardState, isLoadingMentions } = useWsContext();
  return <div style={{ width: "100%", height: "5px" }}>{!dashboardState.customer_id || isLoadingMentions ? <LinearProgress /> : null}</div>;
}

function Monitoring() {
  const { mentionsState, dashboardState, emitNewData, updateAlerts, socketRef, isLoadingMentions, setFilteredMentions } = useWsContext();
  const { tempView, setTempView, setSidebarAlert } = useClientContext();
  const [isUploadingCsv, setIsUploadingCsv] = useState({
    name: "",
    status: "",
  });
  const [currentView, setCurrentView] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [displayedAlerts, setDisplayedAlerts] = useState(0);
  const [isListView, setIsListView] = useState(false);
  const { stringToColor } = useClientContext();

  const apiRef = useGridApiRef();

  const valueOptions = useMemo(() => {
    const fields = ["source", "alertType", "type", "asset"] as const;

    const options: Record<string, Record<string, number>> = fields.reduce((acc, field) => {
      acc[field] = {};
      return acc;
    }, {} as Record<string, Record<string, number>>);

    // Single loop through mentionsState
    mentionsState.forEach((mention) => {
      fields.forEach((field) => {
        const value = mention[field];
        if (value) {
          options[field][value] = (options[field][value] || 0) + 1;
        }
      });
    });

    // Convert options to sorted arrays with value-label objects
    return fields.reduce((acc, field) => {
      acc[field] = Object.entries(options[field])
        .sort((a, b) => b[1] - a[1])
        .map((tuple) => ({ value: tuple[0], label: `${tuple[0]} (${tuple[1]})` }));
      return acc;
    }, {} as Record<string, { value: string; label: string }[]>);
  }, [mentionsState]);

  const autosizeColumns = useCallback(() => {
    if (!apiRef.current?.autosizeColumns) return;
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  }, [apiRef]);

  const handleUploadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !file.type.endsWith("csv")) {
      console.error("No appropriate file selected");
      event.target.value = "";
      return;
    }

    setIsUploadingCsv({ name: file.name, status: "loading" });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csv = e.target?.result;
      if (!csv) return;
      emitNewData({ action: "uploadCsv", csv });
      const handleWebSocketMessage = (event: MessageEvent) => {
        const { data } = JSON.parse(event.data);
        if (data?.csv === "completed") {
          setIsUploadingCsv({ name: file.name, status: "completed" });
        }
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      };
      event.target.value = "";
      socketRef.current?.addEventListener("message", handleWebSocketMessage);
    };
    reader.readAsText(file);
  };

  const updateAlertRequest = useCallback(
    async (mentionToUpdate: MentionType) => {
      const mention = {
        ...mentionToUpdate,
        customer_id: dashboardState.customer_id,
      };

      emitNewData({ action: "updateAlert", mention });
    },
    [dashboardState.customer_id, emitNewData]
  );

  const handleViewChange = (event: SelectChangeEvent<string>) => {
    if (!dashboardState.customer_id) return;
    setCurrentView(event.target.value);
    const allViews = getViews();
    const chosenView = dashboardState.saved_views?.[event.target.value];
    if (!chosenView) return;
    apiRef.current.restoreState(chosenView);
    setViews({ ...allViews, [dashboardState.customer_id]: event.target.value });
    setTempView({});
  };

  const aiFilterValueGetter = useCallback((cell: AiFilterType) => {
    if (!cell) return null;
    if (!cell.results) return cell.status;
    const trueAnswers = cell.results?.filter((q: AiResultType) => {
      return q.content?.booleanAnswer;
    });
    return trueAnswers?.length.toString().padStart(5, "0") + " " + trueAnswers?.map((q) => q.key?.replaceAll("_", " ")).join(" ");
  }, []);

  const aiFilterRenderCell = useCallback(
    (aiFilter: AiFilterType, params: GridRenderCellParams) => {
      if (!aiFilter) return null;
      const fromSimilarFilter = aiFilter.reason === "Inherited";
      if (!aiFilter.results) {
        return (
          <Tooltip title={aiFilter.reason}>
            <Chip size="small" label={aiFilter.status} />
          </Tooltip>
        );
      }

      const tableTrueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.content?.explanation} key={index}>
          <Chip size="small" label={value.key} sx={{ backgroundColor: stringToColor(value.key), color: "black", marginLeft: GlobalSizes.smallGap }} />
        </Tooltip>
      ));

      const listTrueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.key} key={index}>
          <Box
            sx={{
              width: 25,
              height: 5,
              borderRadius: "25%",
              backgroundColor: stringToColor(value.key),
              display: "inline-block",
              marginLeft: GlobalSizes.smallGap,
            }}
          />
        </Tooltip>
      ));

      return (
        <div>
          <Tooltip key={params.row.url} title={labels.ai.success_count(tableTrueQuestions.length) + (fromSimilarFilter ? " (from similar alert)" : "")}>
            <Chip
              size="small"
              icon={fromSimilarFilter ? <FindReplaceIcon /> : <TroubleshootIcon />}
              label={tableTrueQuestions.length}
              sx={{ marginRight: GlobalSizes.smallGap }}
            />
          </Tooltip>
          {isListView ? listTrueQuestions : tableTrueQuestions}
        </div>
      );
    },
    [isListView, stringToColor]
  );

  function parseNumberWithUnits(str: string) {
    const units = { k: 1e3, m: 1e6, b: 1e9, t: 1e12 };
    const regex = /^(\d+(\.\d+)?)([kmbt]?)$/i;
    const match = str?.match(regex);

    if (!match) return null;

    const [, number, , unit] = match;
    const multiplier = unit ? units[unit.toLowerCase() as keyof typeof units] : 1;
    return parseFloat(number) * multiplier;
  }

  function readUnread(mention: MentionType) {
    return mention.read || mention.flagged || mention.hidden || mention.whitelisted || !!mention.manual_severity;
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "avatar",
        headerName: "👤",
        width: 60,
        renderCell: (params: GridRenderCellParams) => {
          const SelectedIcon = SOCIAL_ICONS[params.row.source] || PersonIcon;
          return (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
            >
              <Avatar alt={params.row.user} src={params.value} />
            </Badge>
          );
        },
      },
      {
        field: "user",
        headerName: "User",
        width: 150,
        type: "string" as const,
      },
      {
        field: "source",
        headerName: "Source",
        width: 120,
        type: "singleSelect" as const,
        valueOptions: valueOptions.source,
        valueFormatter: (value: string) => value,
      },
      {
        field: "detection_date",
        headerName: "Detected",
        type: "date" as const,
        valueFormatter: (value: Date) => {
          const date = new Date(value);
          return date.toLocaleDateString(undefined, { month: "short", day: "numeric" });
        },
      },
      {
        field: "creation_date",
        headerName: "Created",
        minWidth: 90,
        type: "date" as const,
        valueGetter: (value: Date) => {
          return value ? new Date(value) : 0;
        },
      },
      {
        field: "description_short",
        headerName: "Content",
        width: 300,
        maxWidth: 600,
        type: "string" as const,
      },
      {
        field: "url",
        headerName: "Link",
        width: 300,
        maxWidth: 600,
        type: "string" as const,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={params.row.url} target="_blank" rel="noopener noreferrer">
              {params.row.url}
            </Link>
          );
        },
      },
      {
        field: "note",
        headerName: "Notes",
        type: "string" as const,
      },
      {
        field: "impressions",
        headerName: "Impressions",
        type: "number" as const,
        valueGetter: (value: number | string) => {
          if (typeof value === "string") {
            return parseNumberWithUnits(value);
          }
          return value;
        },
      },
      {
        field: "alertType",
        headerName: "Risk",
        type: "singleSelect" as const,
        valueOptions: valueOptions.alertType,
        valueFormatter: (value: string) => value,
      },
      {
        field: "type",
        headerName: "Item",
        type: "singleSelect" as const,
        valueOptions: valueOptions.type,
        valueFormatter: (value: string) => value,
      },
      {
        field: "repost",
        headerName: "Repost",
        type: "string" as const,
      },
      {
        field: "severity",
        headerName: "Severity",
        type: "singleSelect" as const,
        editable: true,
        valueGetter: (value: string, params: MentionType) => {
          const finalValue = params?.manual_severity || value || "medium";
          return severity[finalValue.toUpperCase()].value;
        },
        valueOptions: [
          { value: 4, label: "Critical" },
          { value: 3, label: "High" },
          { value: 2, label: "Medium" },
          { value: 1, label: "Low" },
        ],
        renderCell: (params: GridRenderCellParams) => {
          return <SeverityChip alert={params.row} />;
        },
      },
      {
        field: "similar",
        headerName: "Similarity",
        width: 120,
        type: "number" as const,
        valueGetter: (value: { score: number }[]) => {
          const score = value?.[0]?.score;
          return score && score >= similarityThreshold ? score * 100 : null;
        },
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) return null;
          return (
            <div>
              <Tooltip key={params.row.url} title="Click row to see similar alerts">
                <Chip label={params.value.toFixed(2) + "%"} variant="outlined" />
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "asset",
        headerName: "Asset",
        type: "singleSelect" as const,
        valueOptions: valueOptions.asset,
        valueFormatter: (value: string) => value,
      },
      {
        field: "location",
        headerName: "Location",
        type: "string" as const,
      },
      {
        field: "takedown_status",
        headerName: "Takedown Status",
        type: "string" as const,
      },
      {
        field: "ai_filter",
        headerName: labels.ai.title,
        type: "string" as const,
        valueGetter: (cell: AiFilterType, row: MentionType) => {
          return aiFilterValueGetter(cell);
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter, params);
        },
      },
      {
        field: "ai_filter_beta",
        headerName: labels.ai.title_beta,
        valueGetter: (cell: AiFilterType, row: MentionType) => {
          return aiFilterValueGetter(cell);
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter_beta, params);
        },
      },
      {
        field: "refresh_ai_filter",
        headerName: `Refresh ${labels.ai.title}`,
        renderCell: (params: GridRenderCellParams) => {
          return <RefreshAiFilter alert={params.row} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        type: "singleSelect" as const,
        valueGetter: (value: undefined, row: MentionType) => {
          if (row.hidden) return "hidden";
          if (row.flagged) return "flagged";
          if (row.whitelisted) return "whitelisted";
        },
        valueOptions: [
          { value: "hidden", label: "hidden" },
          { value: "flagged", label: "flagged" },
          { value: "whitelisted", label: "whitelisted" },
        ],
      },
    ],
    [aiFilterRenderCell, aiFilterValueGetter, valueOptions]
  );

  const listViewColumns = useMemo(() => columns.filter((col) => col.field !== "status"), [columns]);

  const ListViewCell = useCallback(
    (params: GridRenderCellParams) => {
      const creationDate = new Date(params.row.creation_date);
      const SelectedIcon = SOCIAL_ICONS[params.row.source] || PersonIcon;
      const shortenedFullContent = params.row.full_content?.replace(/\s*\n\s*/g, " ").trim();
      const shortenedDescription = params.row.description_short?.replace(/\s*\n\s*/g, " ").trim();
      return (
        <Stack
          onClick={() => {
            updateAlerts({ ...params.row, read: true });
            setSidebarAlert(params.row);
          }}
          direction="row"
          spacing={GlobalSizes.gap}
          alignItems="flex-start"
          sx={{
            padding: GlobalSizes.gap,
            width: GlobalSizes.fullSize,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
          >
            <Avatar sx={{ width: 54, height: 54 }} alt={params.row.user} src={params.row.avatar} />
          </Badge>
          <Stack flexGrow={GlobalSizes.smallGap} gap={GlobalSizes.smallGap}>
            <Typography variant="subtitle2" fontWeight="bold" color="text.primary" component="span">
              {params.row.user}
              <Link to={params.row.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                <Tooltip title={params.row.url} placement="top">
                  <Typography component="span" variant="caption" color="text.secondary">
                    {" • " + (params.row.source || params.row.url)}
                  </Typography>
                </Tooltip>
              </Link>
              {params.row.creation_date && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {params.row.source && " • "}
                  {creationDate.toLocaleDateString(undefined, { month: "short", day: "numeric" })}
                </Typography>
              )}
              {params.row.severity && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {" • "}
                  <SeverityChip alert={params.row} />
                </Typography>
              )}
              {params.row.ai_filter && <Box my={1}>{aiFilterRenderCell(params.row.ai_filter, params)}</Box>}
            </Typography>
            {shortenedDescription && (
              <Typography variant={params.row.type !== "article" ? "body2" : "body1"} color="text.primary" sx={{ mb: GlobalSizes.smallGap }}>
                {shortenedDescription}...
              </Typography>
            )}
            {shortenedFullContent && (
              <Typography variant="body2" color="text.secondary">
                {shortenedFullContent}...
              </Typography>
            )}
          </Stack>
        </Stack>
      );
    },
    [aiFilterRenderCell, updateAlerts, setSidebarAlert]
  );

  const listColDef: GridListColDef = useMemo(() => {
    return {
      field: "listColumn",
      renderCell: ListViewCell,
    };
  }, [ListViewCell]);

  const restoreStateToDefault = useCallback(() => {
    apiRef.current.restoreState(initialGridState);
  }, [apiRef]);

  const debouncedFilterModelListener = useMemo(
    () =>
      debounce(() => {
        setFilteredMentions(gridFilteredSortedRowEntriesSelector(apiRef).map((m) => m.model) as MentionType[]);
        setTempView(apiRef.current.exportState());
      }, 300),
    [apiRef, setTempView, setFilteredMentions]
  );

  const debouncedTableListener = useMemo(() => debounce(() => setTempView(apiRef.current.exportState()), 300), [apiRef, setTempView]);

  useEffect(() => {
    const restoreViewState = (view?: GridInitialState) => {
      if (tempView.columns && !isLoadingMentions && !isListView) {
        apiRef.current.restoreState(tempView);
      } else if (view) {
        apiRef.current.restoreState(view);
      } else {
        restoreStateToDefault();
      }
    };

    const updateCurrentView = () => {
      const savedView = getViews()[dashboardState.customer_id!];
      const savedViews = dashboardState.saved_views || {};
      const firstSavedView = Object.keys(savedViews)[0];

      if (savedView) {
        setCurrentView(savedView);
        restoreViewState(savedViews[savedView]);
      } else if (firstSavedView) {
        setCurrentView(firstSavedView);
        restoreViewState(savedViews[firstSavedView]);
      } else {
        setCurrentView(labels.monitoring.DEFAULT_VIEW);
        restoreViewState();
      }
    };

    if (dashboardState.customer_id) {
      updateCurrentView();
    }
  }, [dashboardState.customer_id, dashboardState.saved_views, isListView, isLoadingMentions, apiRef, restoreStateToDefault, tempView]);

  useEffect(() => {
    if (!dashboardState.customer_id) return;
    const savedView = getViews()[dashboardState.customer_id];
    if (!savedView && !tempView.columns && dashboardState.customer_id) {
      setTimeout(() => {
        autosizeColumns();
      }, 100);
    }
  }, [dashboardState.customer_id, autosizeColumns, tempView.columns]);

  useEffect(() => {
    if (tempView.columns) {
      apiRef.current.restoreState({ columns: tempView.columns });
    }
  }, [mentionsState, apiRef, tempView.columns]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ToggleButtonGroup
          size="small"
          value={isListView}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, isListView: boolean) => {
            console.log({ event, isListView });
            if (!isListView) {
              if (dashboardState.saved_views?.[currentView]) {
                apiRef.current.restoreState(dashboardState.saved_views[currentView]);
              }
            }
            setIsListView(isListView);
          }}
          aria-label="view type"
        >
          <Tooltip title="Table View">
            <ToggleButton value={false} aria-label="Table View">
              <GridOnIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="List View">
            <ToggleButton value={true} aria-label="List View">
              <List />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Typography fontWeight={"bold"}>Monitoring</Typography>
        {`| Showing ${displayedAlerts} of ${mentionsState.length} Total Alerts`}
        <Tooltip title="Customize filter and column views" placement="top">
          <Button startIcon={<ViewCompactIcon />} onClick={() => setOpenDialog(true)}>
            {currentView && currentView !== labels.monitoring.DEFAULT_VIEW
              ? currentView
              : !Object.keys(dashboardState.saved_views || {}).length && labels.monitoring.DEFAULT_VIEW}
          </Button>
        </Tooltip>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Box sx={{ flexGrow: GlobalSizes.smallGap }} />
        <GridToolbarExport />
        <Tooltip
          title={
            {
              loading: `Uploading ${isUploadingCsv.name}...`,
              completed: `${isUploadingCsv.name} uploaded successfully!`,
              error: `Failed to upload ${isUploadingCsv.name}, please try again!`,
              processing: labels.monitoring.csv,
            }[isUploadingCsv.status] || "Upload alerts via CSV"
          }
        >
          <Button
            size="small"
            component="label"
            startIcon={
              <Badge
                badgeContent={
                  {
                    completed: <DoneOutlinedIcon fontSize="inherit" />,
                    error: <CloseOutlinedIcon fontSize="inherit" />,
                  }[isUploadingCsv.status] || null
                }
              >
                {{ loading: <CircularProgress size={GlobalSizes.smallFontSize} />, processing: <CircularProgress size={GlobalSizes.smallFontSize} /> }[
                  isUploadingCsv.status
                ] || <FileUploadOutlinedIcon fontSize="small" />}
              </Badge>
            }
          >
            <input type="file" hidden accept=".csv" onChange={handleUploadCsv} />
            Import
          </Button>
        </Tooltip>
        {dashboardState.web_socket_connections && <ConnectedUsers />}
      </GridToolbarContainer>
    );
  }

  //!JSX
  return (
    <div>
      <Container
        sx={{
          height: "calc(100vh - 80px)",
          maxHeight: "calc(100vh - 80px)",
          minHeight: "calc(100vh - 80px)",
          minWidth: "calc(100vw - 290px)",
          maxWidth: "calc(100vw - 290px)",
          width: "calc(100vw - 290px)",
          marginTop: 2,
          marginLeft: 0,
        }}
      >
        {openDialog && (
          <CustomTableViews
            openDialog={openDialog}
            currentView={currentView}
            setCurrentView={setCurrentView}
            gridState={apiRef.current.exportState()}
            handleCloseDialog={() => {
              setOpenDialog(false);
            }}
            handleViewChange={handleViewChange}
            restoreStateToDefault={restoreStateToDefault}
          />
        )}
        <LinearLoadingOverlay />
        <DataGridPro
          getRowClassName={(params) => (readUnread(params.row) ? "read-row" : "")}
          sx={{
            border: 0,
            "& .MuiDataGrid-row": {
              border: "1px solid #00000000",
              fontWeight: "500",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#00000000",
                border: "1px solid #ddd",
                boxShadow: "0 0 10px 0 #aaaaaa99",
              },
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#6c73f044",
              "&:hover": {
                backgroundColor: "#6c73f044",
              },
            },
            "& .read-row": {
              backgroundColor: "#6c73f022",
              fontWeight: "Light",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#6c73f022",
                border: "1px solid #aaa",
                boxShadow: "0 0 10px 0 #aaaaaa99",
              },
            },
          }}
          onRowClick={(params) => {
            setSidebarAlert(params.row);
            updateAlerts({ ...params.row, read: true });
          }}
          onColumnVisibilityModelChange={debouncedTableListener}
          onDensityChange={debouncedTableListener}
          onFilterModelChange={debouncedFilterModelListener}
          onSortModelChange={debouncedTableListener}
          onPaginationModelChange={debouncedTableListener}
          onColumnWidthChange={debouncedTableListener}
          onColumnOrderChange={debouncedTableListener}
          onPinnedColumnsChange={debouncedTableListener}
          onStateChange={() => {
            setDisplayedAlerts(mentionsState.length > 0 && apiRef.current.exportState() ? apiRef.current.exportState().pagination?.rowCount ?? 0 : 0);
          }}
          headerFilters
          slots={{ toolbar: CustomToolbar }}
          rows={mentionsState}
          rowHeight={isListView ? 180 : undefined}
          columns={isListView ? listViewColumns : columns}
          unstable_listView={isListView}
          unstable_listColumn={listColDef}
          apiRef={apiRef}
          getRowId={(row) => row.url}
          pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
          pagination
          processRowUpdate={async (newRow, oldRow) => {
            const sevKey = Object.keys(severity).find((key) => severity[key].value === newRow.severity);
            newRow.manual_severity = sevKey?.toLowerCase();
            newRow.severity = oldRow.severity;
            updateAlerts(newRow);
            await updateAlertRequest({ manual_severity: newRow.manual_severity, severity: newRow.severity, url: newRow.url });
            return newRow;
          }}
        />
      </Container>
      <MentionFocus />
    </div>
  );
}

export default Monitoring;
