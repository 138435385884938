import * as Embed from "react-social-media-embed";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { Box } from "@mui/material";
import { GlobalSizes } from "../size";
import { useEffect } from "react";

export default function SocialEmbed(props: { source?: string; url?: string; urn?: string }) {

  useEffect(() => {
    loadLinkedInScript();
  }, []);

  if (!props.url) return null;

  function twitterEmbed(url: string) {
    return url.includes("status") ? (
      <Embed.XEmbed key={props.url} url={url} />
    ) : (
      <TwitterTimelineEmbed key={props.url} sourceType="profile" screenName={url.split("/")[3]} />
    );
  }

  function linkedInEmbed(url: string, urn?: string) {
    if (urn) return <Embed.LinkedInEmbed key={props.url} url={`https://www.linkedin.com/embed/feed/update/${urn}`} />;

    return (
      <div
        className="badge-base LI-profile-badge"
        data-locale="en_US"
        data-size="medium"
        data-theme="light"
        data-type="VERTICAL"
        data-vanity={url.split("/").pop()}
        data-version="v1"
      ></div>
    );
  }

  function loadLinkedInScript() {
    const script = document.createElement("script");
    script.src = "https://platform.linkedin.com/badges/js/profile.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  function tiktokEmbed(url: string) {
    if (url.includes("video")) return <Embed.TikTokEmbed key={props.url} url={url} />;

    return (
      <div>
        <blockquote
          className="tiktok-embed"
          cite={url}
          data-unique-id={url.split("/").pop()?.replace("@", "")}
          data-embed-type="creator"
          style={{ maxWidth: "780px", minWidth: "288px" }}
        >
          <section>
            <a target="_blank" rel="noreferrer" href={`${url}?refer=creator_embed`}>
              {url.split("/").pop()}
            </a>{" "}
          </section>{" "}
        </blockquote>{" "}
        <script async src="https://www.tiktok.com/embed.js"></script>
      </div>
    );
  }

  if (!props.source) return null;
  const element = {
    "facebook.com": <Embed.FacebookEmbed key={props.url} url={props.url} />,
    "twitter.com": twitterEmbed(props.url),
    "x.com": twitterEmbed(props.url),
    "instagram.com": <Embed.InstagramEmbed key={props.url} url={props.url} />,
    "linkedin.com": linkedInEmbed(props.url, props.urn),
    "tiktok.com": tiktokEmbed(props.url),
    "youtube.com": <Embed.YouTubeEmbed key={props.url} url={props.url} />,
  }[props.source];

  if (!element) return null;
  return <Box sx={{ maxHeight: GlobalSizes.cards.height, marginTop: GlobalSizes.gap }}>{element}</Box>;
}
