import { useState } from "react";
import { Container, Stack, Typography } from "@mui/material";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { COVERAGE, labels } from "../labels";
import { AssetCard, searchType } from "../Components/assets/AssetCard";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { entries } from "../state";
import { useWsContext } from "../ws-context";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Done from '@mui/icons-material/Done';
import List from '@mui/material/List';

function Assets() {

  const { updateDashboard, dashboardState, emitNewData } = useWsContext();
  const [newAsset, setNewAsset] = useState<string | null>(null);

  const saveAsset = async () => {

    if (!newAsset) return;

    updateDashboard({
      assets: { ...(dashboardState.assets || {}), [newAsset.trim()]: { type: "Term" } }
    });

    emitNewData({
      action: "setAsset",
      asset: {
        [newAsset.trim()]: { type: "Term" },
      },
    });

    setNewAsset('');
  };

  const hint = searchType(newAsset).label;

  return (
    <div>
      <Bar label={labels.assets.title + " | " + labels.assets.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }} >
        <Stack direction="column" gap={GlobalSizes.gap}>
          <Typography variant="body2">Coverage: {COVERAGE.join(", ")}</Typography>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel>{hint}</InputLabel>
            <OutlinedInput
              onChange={(e) => setNewAsset(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') saveAsset();
                if (e.key === 'Escape') setNewAsset('');
              }}
              value={newAsset}
              id="assetInput"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={saveAsset}
                    edge="end"
                  >
                    <Done />
                  </IconButton>
                </InputAdornment>
              }
              label={hint}
            />
          </FormControl>

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {entries(dashboardState.assets).map(([key, value]) => (
              <AssetCard key={key} asset={key} value={value} />
            ))}
          </List>
          <Loader />
        </Stack>
      </Container>
    </div>
  );
}

export default Assets;
