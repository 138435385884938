import { Typography, Container } from "@mui/material";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import { useWsContext } from "../ws-context";
import InquiriesCharts from "../Components/InquiriesCharts";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { UsageStats } from "../Components/home/UsageStats";

function Home() {
  const { mentionsState, filteredMentions } = useWsContext();

  return (
    <div>
      <Bar label={labels.home.title} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Brinker.ai
        </Typography>
        <UsageStats allMentions={mentionsState} filteredMentions={filteredMentions} />
        <InquiriesCharts mentions={filteredMentions} />
        <Typography fontSize={8}>
          <a href="/push-data.json" target="_blank" rel="noopener noreferrer">
            Version data
          </a>
        </Typography>
      </Container>
    </div>
  );
}

export default Home;
