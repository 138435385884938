import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";

const ButtonClickTracker: React.FC = () => {
  useEffect(() => {
    const handleButtonClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      mixpanel.track("Click", {
        innerText: target.innerText,
        elementId: target.id || "",
        className: target.className || "",
        tagName: target.tagName,
      });
    };
    document.addEventListener("click", handleButtonClick);

    return () => {
      document.removeEventListener("click", handleButtonClick);
    };
  }, []);

  return null;
};

export default ButtonClickTracker;
