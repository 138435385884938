import { Newspaper } from '@mui/icons-material';
import CloseIcon from "@mui/icons-material/Close";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
    Button,
    Tooltip
} from "@mui/material";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback } from "react";
import { SimilarAlerts } from "../Components/SimilarAlerts";
import { SOCIAL_ICONS } from "../labels";
import { MentionType } from "../state";
import { useWsContext } from "../ws-context";
import { useClientContext } from '../client-context';
import AiFilterView from "./investigation/ai-filter/AiFilterView";
import GeneralView from "./investigation/GeneralView";
import TimeLine from "./investigation/TimeLine";
import Note from "./Note";
import SocialEmbed from "./SocialEmbed";
import StatusButtons from './StatusButtons';
import { GlobalSizes } from '../size';


function MentionFocus() {
    const { updateAlerts, mentionsState, emitNewData, dashboardState } = useWsContext();
    const { sidebarAlert, setSidebarAlert } = useClientContext();
    const updateAlertRequest = useCallback(
        async (mentionToUpdate: MentionType) => {
            const mention = {
                ...mentionToUpdate,
                customer_id: dashboardState.customer_id,
            };

            emitNewData({ action: "updateAlert", mention });
        },
        [dashboardState.customer_id, emitNewData]
    );


    const updateWhitelisted = useCallback(
        (mentionToUpdate: MentionType) => {
            if (mentionToUpdate.whitelisted) {
                const { alertType, ...rest } = mentionToUpdate;
                updateAlerts({ ...rest, severity: "low" });
                updateAlertRequest({ url: mentionToUpdate.url, severity: "low", whitelisted: true });
                return;
            }
            updateAlerts(mentionToUpdate);
            updateAlertRequest({ url: mentionToUpdate.url, whitelisted: false });
        },
        [updateAlertRequest, updateAlerts]
    );

    const handleClose = () => {
        setSidebarAlert();
    };

    const SelectedIcon = SOCIAL_ICONS[sidebarAlert?.source || ""] || Newspaper;

    function WhitelistButton({ mention }: { mention: MentionType | undefined }) {
        if (!mention) return null;
        return <Tooltip title={mention.whitelisted ? "Remove from whitelist" : "Add to whitelist"} placement="top">
            <Button
                sx={{ marginTop: GlobalSizes.gap }}
                color={mention.whitelisted ? "success" : "inherit"}
                onClick={() => {
                    updateWhitelisted({ ...mention, whitelisted: !mention.whitelisted });
                    mention.whitelisted = !mention.whitelisted;
                }}
                aria-label="Whitelist Alert"
                startIcon={<VerifiedUserIcon color={!mention.whitelisted ? "disabled" : "action"} />}
            >
                {mention.whitelisted ? "Whitelisted" : "Add to Whitelist"}
            </Button>
        </Tooltip>;
    }

    return (
        <SwipeableDrawer
            variant="persistent"
            open={!!sidebarAlert}
            onClose={handleClose}
            anchor={'right'}
            onOpen={console.log} >
            <Toolbar>
                <SelectedIcon />
                <Box sx={{ flexGrow: 0.01 }} />
                <Typography fontWeight={"bold"} >
                    {sidebarAlert?.source}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>

            </Toolbar>
            <Divider />
            <Box sx={{ width: 600, padding: 2 }}>
                <StatusButtons alert={sidebarAlert} />
                <GeneralView alert={sidebarAlert} />
                <SimilarAlerts alert={sidebarAlert} allMentions={mentionsState} />
                <AiFilterView alert={sidebarAlert} />
                <Note mention={sidebarAlert} />
                <TimeLine alert={sidebarAlert} />
                <WhitelistButton mention={sidebarAlert} />
                <SocialEmbed source={sidebarAlert?.source} url={sidebarAlert?.url} urn={sidebarAlert?.urn} />
            </Box>
        </SwipeableDrawer>
    );
}
export default MentionFocus;