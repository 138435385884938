import { Button, CardContent, Stack, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FC } from "react";
import { engagementCount } from "../../../labels";
import { GlobalSizes, TweetCardContentSizes } from "../../../size";
import { TweetDataType } from "../../../state";

interface TweetCardContentProps {
  tweetData: TweetDataType;
}

const TweetCardContent: FC<TweetCardContentProps> = ({ tweetData }) => {
  const metrics = tweetData.public_metrics;

  const getTweetText = (tweetText?: string, displayUrl?: string) => {
    if (!tweetText) return "";
    if (displayUrl && !tweetText.replace(/https?:\/\/[^\s]+/g, "").trim()) {
      return displayUrl;
    }

    const parsedElement = new DOMParser().parseFromString(tweetText, "text/html");
    return parsedElement.documentElement?.textContent;
  };

  const formatDate = (dateToFormat: string) => {
    const date = new Date(dateToFormat);
    const timeString = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const dateString = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${timeString} · ${dateString}`;
  };

  return (
    <CardContent>
      <Typography
        variant="body2"
        sx={{
          fontSize: GlobalSizes.smallFontSize,
          mb: GlobalSizes.gap,
          color: "black",
          whiteSpace: "pre-wrap",
        }}
      >
        {getTweetText(tweetData.text, tweetData.entities?.urls?.[0]?.display_url)}
      </Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: GlobalSizes.gap, borderColor: "gray" }}>
        <Typography variant="subtitle2" sx={{ color: "rgb(83, 100, 113)", fontWeight: "bold", mb: GlobalSizes.gap }}>
          {`${formatDate(tweetData.created_at)}`}
        </Typography>
        <InfoOutlinedIcon sx={{ color: "gray" }} />
      </Stack>
      <Stack direction="row" spacing={GlobalSizes.gap}>
        <div className="tweet_icons_wrapper">
          <FavoriteIcon sx={{ fill: "rgb(249, 24, 128)", fontSize: GlobalSizes.bigFontSize }} />
          <span className="tweet_icons_text">{metrics?.like_count !== 0 ? engagementCount(Number(metrics?.like_count)) : ""}</span>
        </div>
        <div className="tweet_icons_wrapper">
          <ReplySVG style={{ ...TweetCardContentSizes.tweetIconSize, fill: "#1DA1F2" }} />
          <span className="tweet_icons_text">Reply</span>
        </div>
        <div className="tweet_icons_wrapper">
          <img
            src="copy-link-icon.png"
            alt="comment"
            style={{
              ...TweetCardContentSizes.tweetIconSize,
              fill: "rgb(29, 155, 240)",
              fontWeight: "bold",
            }}
          />
          <span className="tweet_icons_text">Copy link</span>
        </div>
      </Stack>
      <Stack justifyContent="center" sx={{ width: GlobalSizes.fullSize }}>
        <Button
          variant="outlined"
          sx={{
            width: GlobalSizes.fullSize,
            textTransform: "none",
            border: `${GlobalSizes.borderWidth} solid rgb(224,224,224)`,
            fontWeight: "bold",
            color: "rgb(0, 111, 214)",
          }}
        >
          {metrics?.reply_count !== 0 ? `Read ${engagementCount(Number(metrics?.reply_count))} replies` : "Read more on X"}
        </Button>
      </Stack>
    </CardContent>
  );
};

interface CustomSVGProps extends React.SVGProps<SVGSVGElement> {
  style?: React.CSSProperties & { enableBackground?: string };
}

const ReplySVG = (props: CustomSVGProps) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={GlobalSizes.none}
    y={GlobalSizes.none}
    width={TweetCardContentSizes.SvgSizes.replyIconDimension}
    height={TweetCardContentSizes.SvgSizes.replyIconDimension}
    viewBox="0 0 501.133 501.133"
    style={{
      enableBackground: "new 0 0 501.133 501.133",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M144.421,501.133c-0.023,0-0.047,0-0.071,0c-6.871,0-12.625-3.104-15.793-8.482c-5.222-8.842-2.613-21.013,9.008-41.996 c14.212-25.664,22.352-53.593,24.27-83.199c-95.4-28-158.778-98.332-158.778-177.427C3.056,85.245,114.087,0,250.567,0 c136.477,0,247.51,85.245,247.51,190.028c0,81.323-66.815,153.21-166.571,179.607C177.826,500.831,153.55,501.133,144.421,501.133z  M250.567,24.787c-122.814,0-222.729,74.13-222.729,165.241c0,70.252,60.228,132.995,149.873,156.106l9.49,2.429l-0.186,9.807 c-0.709,37.226-10.06,72.323-27.769,104.301c-2.308,4.161-3.984,7.519-5.198,10.22c16.68-8.086,59.412-34.95,163.527-123.954 l2.199-1.886l2.802-0.703c90.156-22.946,150.707-85.754,150.707-156.319C473.282,98.917,373.366,24.787,250.567,24.787z" />
    </g>
  </svg>
);

export default TweetCardContent;
