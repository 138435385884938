import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useWsContext } from "../ws-context";
import { GlobalSizes } from '../size';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useClientContext } from "../client-context";
import { useMemo } from 'react';
import Avatar from '@mui/material/Avatar';

export default function AlertSelect({ destination }: { destination: string }) {
    const { mentionsState } = useWsContext();
    const alerts = useMemo(
        () => mentionsState.filter((m) => m.flagged || m.ai_filter?.status === 'success').sort((b, a) => (a.flag_date || 0) - (b.flag_date || 0)).slice(0, 100),
        [mentionsState],
    );
    const nav = useNavigate();
    const { setCurrentAlert, currentAlert } = useClientContext();

    return (
        <Autocomplete
            id="alert-select"
            value={currentAlert}
            sx={{ width: GlobalSizes.fullSize }}
            options={alerts}
            autoHighlight
            getOptionLabel={(alert) => `${alert.user}: ${alert.description_short} - ${alert.url}`}
            onChange={(event, newValue) => {
                if (!newValue) return;
                setCurrentAlert(newValue);
                nav(`/${destination}?url=${encodeURIComponent(newValue.url)}`, { replace: true });
            }}
            renderOption={(props, alert) => {
                let dateString = null;
                if (alert.flag_date) {
                    dateString = ' - flagged ' + (new Date(alert.flag_date)).toLocaleDateString(undefined, { month: "short", day: "numeric" });
                }
                return (
                    <Box
                        key={alert.url}
                        component="li"
                        sx={{ display: 'flex', alignItems: 'center', gap: GlobalSizes.smallGap, cursor: 'pointer' }}
                        {...props}
                    >
                        <Avatar alt={alert.user} src={alert.avatar} />
                        <Stack>
                            <Box  >
                                <Typography fontWeight='bold' variant='caption' noWrap>{alert.user}</Typography>
                                <Typography fontWeight='light' variant='caption' noWrap>{dateString}</Typography>
                            </Box>
                            <Typography noWrap>{alert.description_short}</Typography>
                            <Typography fontWeight='light' variant='caption'>{alert.url}</Typography>
                        </Stack>
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Alert"
                />
            )}
        />
    );
}