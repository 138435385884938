import { MentionType } from "../../state";
import ForceGraph2D from "react-force-graph-2d";
import { Box, Typography, Tooltip } from "@mui/material";
import { GlobalSizes } from "../../size";
import { severity } from "../../labels";
import MentionFocus from "../MentionFocus";
import { useEffect, useMemo, useState } from "react";
import { similarityThreshold } from "../../labels";
import { useClientContext } from "../../client-context";

function EmbeddingsNetworkChart2D({ mentions, stringToColor }: { mentions: MentionType[]; stringToColor: (str: string) => string }) {
  const { setSidebarAlert } = useClientContext();
  const [imgCache, setImgCache] = useState<{ [key: string]: HTMLImageElement }>({});

  const { myData } = useMemo(() => {
    const myData = {
      nodes: [] as any[],
      links: [] as any[],
    };

    for (const mention of mentions) {
      const node = {
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        image: mention.avatar,
        source: mention.source,
        val: 1,
      };

      myData.nodes.push(node);

      for (const similarMention of mention.similar || []) {
        if (similarMention.score < similarityThreshold) continue;
        if (mentions.findIndex((m) => m.url === similarMention.url) === -1) continue;
        if ((mention.creation_date || 0) > (similarMention.creation_date || 0)) {
          myData.links.push({
            source: similarMention.url,
            target: mention.url,
            similarity: similarMention.score,
          });
        } else {
          myData.links.push({
            source: mention.url,
            target: similarMention.url,
            similarity: similarMention.score,
          });
        }
      }
    }
    return { myData };
  }, [mentions]);

  useEffect(() => {
    // Preload images and cache them
    myData.nodes.forEach((node) => {
      if (node.image && !imgCache[node.image]) {
        const img = new Image();
        img.src = node.image;
        img.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.image]: img }));
      }
    });
  }, [mentions, imgCache, myData.nodes]);

  return (
    <Box>
      <Tooltip title="Filtered in the monitoring view">
        <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
          AI Narrative Network ({mentions.length} alerts)
        </Typography>
      </Tooltip>
      <ForceGraph2D
        graphData={myData}
        width={1000}
        height={500}
        warmupTicks={100}
        nodeColor={(node) => stringToColor(node.name)}
        nodeLabel={(node) => `${node.source} - ${node.name}`}
        linkWidth={1}
        linkLabel={(link) => `${link.similarity.toFixed(2) * 100 + "% similar"}`}
        linkColor={(link) => {
          if (link.similarity > 0.95) return severity.CRITICAL.color;
          if (link.similarity > 0.9) return severity.HIGH.color;
          if (link.similarity > 0.85) return severity.MEDIUM.color;
          return severity.LOW.color;
        }}
        onNodeClick={(node) => setSidebarAlert(mentions.find((m) => m.url === node.id))}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const radius = 4 / globalScale;
          ctx.beginPath();
          ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
          ctx.fillStyle = stringToColor(node.source); // or any color you like
          ctx.fill();

          const img = imgCache[node.image];
          if (img) {
            const size = radius * 4;
            ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
          }
        }}
      />
      <MentionFocus />
    </Box>
  );
}

export default EmbeddingsNetworkChart2D;
