import { useEffect, useState } from "react";
import { Container, Button, Box, Stack, CircularProgress, Typography } from "@mui/material";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { MITIGATIONS, labels } from "../labels";
import { MitigationType, MentionType } from "../state";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { Link } from "react-router-dom";
import MitigationButton from "../Components/action/MitigationButton";
import { useLocation } from "react-router-dom";
import RefreshButton from "../Components/RefreshButton";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";
import AlertSelect from "../Components/AlertSelect";
import GeneralView from "../Components/investigation/GeneralView";

export type MitigationKeys = keyof typeof MITIGATIONS;
type MitigationEntry = {
  promptId: string;
  emails: string[];
  supportedDomains?: string[];
};

function Action() {
  const [isLoading, setIsLoading] = useState({
    takedown: false,
    dossier: "",
    mitigation: "",
  });

  const [isError, setIsError] = useState({
    dossier: false,
    mitigation: false,
  });

  const [chosenAction, setChosenAction] = useState<string | undefined>(undefined);

  const { mentionsState, updateAlerts, emitNewData, socketRef } = useWsContext();
  const { currentAlert } = useClientContext();

  const flaggedAlerts = mentionsState.filter((m) => m.flagged);

  const location = useLocation();

  const handleTakedown = async (alert: MentionType) => {
    if (!chosenAction) return;
    emitNewData({ action: "takedown", url: chosenAction });
    updateAlerts({ ...alert, takedown_status: "submitted" });
  };

  const createDossierFile = async (alert: MentionType) => {
    setIsLoading((prev) => ({ ...prev, dossier: "loading" }));
    setIsError((prev) => ({ ...prev, dossier: false }));
    updateAlerts({ ...alert, mitigation_overview: undefined });
    emitNewData({ action: "dossier", url: alert.url });
    const handleWebSocketMessage = (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.updatedAlert?.url === alert.url && data.updatedAlert.mitigation_overview) {
        setIsLoading((prev) => ({ ...prev, dossier: "" }));
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
      if (data?.error?.details?.action === "dossier" && data.error.details.url === alert.url) {
        setIsLoading((prev) => ({ ...prev, dossier: "" }));
        setIsError((prev) => ({ ...prev, dossier: true }));
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  const handleMitigation = async (mitigation: MitigationKeys, alert: MentionType) => {
    console.log({ alert });

    console.log("Mitigation request");
    setIsLoading((prev) => ({ ...prev, mitigation: "loading" }));
    setIsError((prev) => ({ ...prev, mitigation: false }));
    emitNewData({ action: "mitigation", url: alert.url, promptId: MITIGATIONS[mitigation].promptId });
    const handleWebSocketMessage = (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.updatedAlert?.url === alert.url && data.updatedAlert[MITIGATIONS[mitigation].promptId]) {
        setIsLoading((prev) => ({ ...prev, mitigation: "" }));
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
      if (data?.error?.details?.action === "mitigation" && data.error.details.url === alert.url) {
        setIsLoading((prev) => ({ ...prev, mitigation: "processing" }));
        setIsError((prev) => ({ ...prev, mitigation: true }));
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  const findMitigationSources = (parentMention: string, source: string | undefined) => {
    const sourceArray: string[] = source ? [source] : [];
    const childAlerts = mentionsState.filter((m) => m.parent_mention === parentMention).map((m) => m.url);

    childAlerts.forEach((key) => {
      try {
        let source = new URL(key.toLowerCase()).host;
        source = source.replace("www.", "");
        if (!sourceArray?.includes(source)) {
          sourceArray?.push(source);
        }
      } catch (error) {
        console.error(`Error parsing URL ${key}:`, error);
      }
    });
    return sourceArray;
  };

  const shouldRenderMitigationButton = (mitigation: MitigationEntry, alert: MentionType) => {
    if (mitigation.promptId === "mitigation_ai_content_analysis" && !alert.ai_filter?.results?.length) {
      return false;
    }
    return (
      !mitigation.supportedDomains ||
      findMitigationSources(alert.url, alert.source)?.some((source) => mitigation.supportedDomains?.some((domain: string) => source === domain))
    );
  };

  useEffect(() => {
    const clickedAction = new URLSearchParams(location.search).get("url");
    const alert = flaggedAlerts.find((m) => m.url === clickedAction);

    if (alert) {
      setChosenAction(alert.url);
    } else if (flaggedAlerts.length) {
      setChosenAction(flaggedAlerts[0].url);
    }
  }, [location.search, mentionsState, flaggedAlerts]);

  const renderContent = (alert: MentionType) => {
    return (
      <Stack key={alert.url}>
        <h2>Takedown by Brinker</h2>
        Request removal of the content from the social platform
        <Stack direction="row" alignItems="center" marginTop={GlobalSizes.gap} gap={GlobalSizes.gap}>
          <div>
            <Stack direction="row" justifyItems="center" alignItems="center" gap={GlobalSizes.gap}>
              <Button
                sx={{ marginBottom: GlobalSizes.gap }}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => handleTakedown(alert)}
                disabled={!!alert.takedown_status}
              >
                <Stack direction="column" alignItems="self-start">
                  Takedown
                  <Typography variant="caption">{alert.takedown_status}</Typography>
                </Stack>
              </Button>
              {isLoading.takedown && <CircularProgress />}
            </Stack>
            <h2>Investigation Overview</h2>
            Generate an investigation overview file
            <Stack direction="row" alignItems="center" gap={GlobalSizes.gap}>
              <Button
                disabled={isLoading.dossier === "loading"}
                sx={{ mb: GlobalSizes.gap, mt: GlobalSizes.gap }}
                variant="contained"
                size="large"
                onClick={() => createDossierFile(alert)}
              >
                Generate
              </Button>
              {alert.mitigation_overview && !isLoading.dossier && (
                <Link target="_blank" to={alert.mitigation_overview}>
                  Overview file
                </Link>
              )}
              {isLoading.dossier === "loading" && <CircularProgress />}
            </Stack>
            {isLoading.dossier === "processing" && <RefreshButton label={labels.takeAction.overview} />}
            {isError.dossier && <Typography color="error">Failed generating overview, please try again</Typography>}
          </div>
        </Stack>
        <div>
          <h2>Mitigation</h2>
          Threat mitigation options
          <Stack direction="column" gap={GlobalSizes.gap}>
            <Stack direction="row" alignItems="center" gap={GlobalSizes.gap}>
              {Object.entries(MITIGATIONS).map(([mitigationKey, mitigation]) => {
                return alert.url && shouldRenderMitigationButton(mitigation, alert) ? (
                  <MitigationButton
                    key={mitigationKey}
                    mitigation={mitigationKey as MitigationKeys}
                    isLoading={isLoading.mitigation}
                    handleMitigation={() => handleMitigation(mitigationKey as MitigationKeys, alert)}
                    mitigationText={alert?.[mitigation.promptId as keyof MitigationType]}
                  />
                ) : null;
              })}
            </Stack>
            {isLoading.mitigation === "loading" ? (
              <CircularProgress />
            ) : isLoading.mitigation === "processing" ? (
              <div>
                {" "}
                <RefreshButton label={labels.takeAction.mitigation} />
              </div>
            ) : null}
            {isError.mitigation && <Typography color="error">Failed generating mitigation, please try again</Typography>}
          </Stack>
        </div>
      </Stack>
    );
  };

  return (
    <div>
      <Bar label={labels.takeAction.title + " | " + labels.takeAction.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Box sx={{ width: GlobalSizes.fullSize, typography: "body1" }}>
          <AlertSelect destination="action" />
          <GeneralView alert={currentAlert} />
          {currentAlert && renderContent(currentAlert)}
          <Loader />
        </Box>
      </Container>
    </div>
  );
}

export default Action;
