import { Avatar, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import { MentionType } from "../../state";
function GeneralView({ alert }: { alert?: MentionType }) {
  if (!alert) return null;
  return (
    <div>
      <Card sx={{ my: GlobalSizes.mediumGap }}>
        <CardContent>
          <CardContent>
            <CardHeader sx={{ pl: GlobalSizes.none }} avatar={<Avatar alt={alert?.user} src={alert?.avatar} />} title={alert?.user} />
            <Typography variant="body2" className="report_description" color="text.secondary">
              {alert?.description_short}
            </Typography>
            <Typography variant="body2" className="report_description" color="text.secondary">
              <a href={alert?.url} target="_blank" rel="noreferrer">
                {alert?.url}
              </a>
            </Typography>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}

export default GeneralView;
