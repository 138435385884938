import { Box, TextField, Button, Stack, CircularProgress } from "@mui/material";
import { IntegrationFields, IntegrationType } from "../Pages/SiemIntegration";
import { GlobalSizes } from "../size";

interface SiemFormProps {
  integration: IntegrationType;
  token: string;
  endpoint: string;
  isLoading: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, integration: IntegrationType, field: IntegrationFields) => void;
  handleIntegration: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, integration: IntegrationType) => Promise<void>;
}

function SiemForm(props: SiemFormProps) {
  const { endpoint, handleChange, handleIntegration, integration, isLoading, token } = props;
  return (
    <Box
      component="form"
      sx={{
        width: "auto",
        marginTop: GlobalSizes.gap,
        display: "flex",
        alignItems: "center",
        gap: GlobalSizes.gap,
      }}
    >
      <TextField
        id="outlined-search"
        label={`${integration === "splunk" ? "HEC" : "Sentinel"} token`}
        type="search"
        value={token}
        onChange={(e) => handleChange(e, integration, "token")}
      />
      <TextField
        id="outlined-search"
        label={`${integration === "splunk" ? "Splunk" : "Sentinel"} endpoint`}
        type="search"
        value={endpoint}
        onChange={(e) => handleChange(e, integration, "endpoint")}
      />
      <Stack direction="row" gap={GlobalSizes.gap}>
        <Button variant="contained" onClick={(e) => handleIntegration(e, integration)} disabled={isLoading}>
          save
        </Button>
        {isLoading && <CircularProgress />}
      </Stack>
    </Box>
  );
}

export default SiemForm;
