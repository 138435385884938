import { MentionType } from "../../../state";
import { IconButton, Tooltip } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { labels } from "../../../labels";
import { useWsContext } from "../../../ws-context";

function RefreshAiFilter({ alert }: { alert: MentionType }) {
  const { updateAlerts, emitNewData } = useWsContext();

  const deleteAiFilter = async () => {
    const { ai_filter, ...alertAttributes } = alert;
    const newAlert = { ...alertAttributes };
    updateAlerts(newAlert);
    emitNewData({ action: "removeAlertAttribute", url: alert.url, attribute: "ai_filter" });
  };

  return (
    <Tooltip title={`Refresh ${labels.ai.title}`} placement="top">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          deleteAiFilter();
        }}
      >
        <Refresh />
      </IconButton>
    </Tooltip>
  );
}

export default RefreshAiFilter;
