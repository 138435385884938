import { useEffect, useState } from "react";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import { Typography, List, ListItem, ListItemText, Divider, Link } from "@mui/material";
import SiemForm from "../Components/SiemForm";
import { GlobalSizes } from "../size";
import { useWsContext } from "../ws-context";

export type IntegrationType = "splunk" | "sentinel";
export type IntegrationFields = "token" | "endpoint";

function SiemIntegration() {
  const [integrations, setIntegrations] = useState({
    splunk: {
      token: "",
      endpoint: "",
    },
    sentinel: {
      token: "",
      endpoint: "",
    },
  });
  const [isLoading, setIsLoading] = useState({
    splunk: false,
    sentinel: false,
  });

  const { dashboardState, updateDashboard, emitNewData } = useWsContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, integration: IntegrationType, field: IntegrationFields) => {
    const { value } = e.target;
    setIntegrations((prevState) => ({
      ...prevState,
      [integration]: {
        ...prevState[integration],
        [field]: value,
      },
    }));
  };

  const handleIntegration = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, integration: IntegrationType) => {
    event.preventDefault();
    updateDashboard({ settings: { ...dashboardState.settings, siem: integrations } });
    setIsLoading((prev) => ({ ...prev, [integration]: true }));
    emitNewData({ action: "setSiem", siem: integrations });
    setIsLoading((prev) => ({ ...prev, [integration]: false }));
  };

  useEffect(() => {
    const siem = dashboardState.settings?.siem;
    if (siem) {
      const integrations = {
        splunk: siem.splunk || { token: "", endpoint: "" },
        sentinel: siem.sentinel || { token: "", endpoint: "" },
      };
      setIntegrations(integrations);
    }
  }, [dashboardState.settings?.siem]);

  return (
    <div>
      <Bar label={labels.siemIntegration.title + " | " + labels.siemIntegration.subtitle} />
      <div style={{ padding: GlobalSizes.mediumGap }}>
        <Typography variant="h5" sx={{ my: GlobalSizes.mediumGap }}>
          Integration Instructions
        </Typography>
        <Divider style={{ marginBottom: GlobalSizes.mediumGap }} />
        <List>
          {labels.siemIntegration.splunk.map((instruction) => {
            return (
              <ListItem key={instruction}>
                <ListItemText primary={instruction} />
              </ListItem>
            );
          })}
        </List>
        <Link href="https://docs.splunk.com/Documentation/Splunk/latest/Data/UsetheHTTPEventCollector" target="_blank">
          Splunk HEC Documentation
        </Link>
        <SiemForm
          endpoint={integrations.splunk.endpoint}
          handleChange={handleChange}
          handleIntegration={handleIntegration}
          integration="splunk"
          isLoading={isLoading.splunk}
          token={integrations.splunk.token}
        />
        <Divider sx={{ marginTop: GlobalSizes.gap }} />
        <List>
          {labels.siemIntegration.sentinel.map((instruction) => {
            return (
              <ListItem key={instruction}>
                <ListItemText primary={instruction} />
              </ListItem>
            );
          })}
        </List>
        <Link href="https://docs.microsoft.com/en-us/azure/sentinel/connect-data-sources" target="_blank">
          Microsoft Sentinel Data Connectors Documentation
        </Link>
        <SiemForm
          endpoint={integrations.sentinel.endpoint}
          handleChange={handleChange}
          handleIntegration={handleIntegration}
          integration="sentinel"
          isLoading={isLoading.sentinel}
          token={integrations.sentinel.token}
        />
        <Typography variant="body1" style={{ marginTop: GlobalSizes.mediumGap }}>
          Note: Ensure you have the necessary permissions and access rights before attempting these integrations. Contact your IT administrator if you encounter
          any issues.
        </Typography>
      </div>
    </div>
  );
}

export default SiemIntegration;
