import { AssetType } from "../../state";
import { unixToLocaleDate } from "../../labels";
import { IconButton, Tooltip } from "@mui/material";
import { useWsContext } from "../../ws-context";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import React from "react";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TagIcon from "@mui/icons-material/Tag";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { SOCIAL_ICONS } from "../../labels";

function AssetCard({ asset, value }: { asset: string; value: AssetType }) {
  const { mentionsState, dashboardState, updateDashboard, emitNewData } = useWsContext();

  const { type, ...restValues } = value;

  const alertsCount = <div>{mentionsState.filter((mention) => mention.asset === asset).length.toLocaleString()} alerts</div>;

  const formatValue = (key: string, value: (string | number) | { [key: string]: number }) => {
    if (typeof value === "object") {
      return Object.entries(value).map(([childKey, value]) => {
        return (
          <div>
            {key} {childKey}: {unixToLocaleDate(value)}
          </div>
        );
      });
    }
    if (key.includes("date")) {
      return (
        <div>
          {key}: {unixToLocaleDate(value as number)}
        </div>
      );
    }
    return <div>value</div>;
  };

  const moreInfo = Object.entries(restValues).map(([key, value]) => {
    return formatValue(key, value);
  });

  const deleteAsset = async (asset: string) => {
    console.log("deleting asset", asset);
    if (!dashboardState.assets) return;
    updateDashboard({
      assets: Object.fromEntries(Object.entries(dashboardState.assets).filter(([key]) => key !== asset)),
    });
    emitNewData({
      action: "removeAsset",
      asset: {
        [asset]: dashboardState.assets[asset],
      },
    });
  };

  return (
    <ListItem
      secondaryAction={
        <Tooltip title={`Delete ${asset} (does not delete alerts)`}>
          <IconButton edge="end" aria-label="delete" onClick={() => deleteAsset(asset)}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemAvatar>{searchType(asset).icon}</ListItemAvatar>
      <Tooltip title={moreInfo}>
        <ListItemText
          primary={searchType(asset).format(asset)}
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" sx={{ color: "text.primary", display: "inline" }}>
                {alertsCount}
              </Typography>
              {searchType(asset).label}
            </React.Fragment>
          }
        />
      </Tooltip>
    </ListItem>
  );
}
const searchType = (asset: string | null) => {
  const defaultType = (overrides: Partial<{ label: string; format: (asset: string) => string; icon: JSX.Element | null }>) => ({
    label: "Monitor this item",
    format: (asset: string) => asset,
    icon: <GpsFixedIcon />,
    ...overrides,
  });

  if (!asset) return defaultType({ label: 'Enter term, user, #hashtag or "exact phrase" to monitor', icon: null });

  if (asset.includes("https://")) {
    const domain = new URL(asset).hostname.replace("www.", "");
    const SelectedIcon = SOCIAL_ICONS[domain];
    return defaultType({
      label: !!SelectedIcon ? "Monitor this user/page" : "Monitor this URL",
      icon: SelectedIcon ? <SelectedIcon /> : <GpsFixedIcon />,
    });
  }

  if (asset.startsWith("#")) return defaultType({ label: "Monitor this #hashtag", icon: <TagIcon /> });
  if (asset.startsWith("@")) return defaultType({ label: "Monitor this @userName", icon: <AccountCircleIcon /> });
  if (asset.startsWith('"') && asset.endsWith('"')) { return defaultType({ label: 'Monitor this "exact phrase"', icon: <FormatQuoteIcon /> }); }

  if (asset.includes(" ")) {
    return defaultType({
      label: "Monitor items that contain ALL of these words",
      icon: <SpeakerNotesIcon />,
      format: (asset: string) => asset.replaceAll(" ", " ➕ "),
    });
  }

  return defaultType({});
};
export { AssetCard, searchType };
