import { Card, IconButton } from "@mui/material";
import React from "react";
import TweetCardHeader from "./TweetCardHeader";
import TweetCardContent from "./TweetCardContent";
import { GlobalSizes, TweetCardSizes } from "../../../size";
import { MentionType } from "../../../state";

interface TweetCardProps {
  alert: MentionType;
}

const TweetCard = React.forwardRef<HTMLDivElement, TweetCardProps>(({ alert }, ref) => {
  const tweetData = alert?.twitter_data?.data;
  return (
    <Card
      ref={ref}
      sx={{
        ...TweetCardSizes.size,
        backgroundColor: "white",
        borderRadius: TweetCardSizes.borderRadius,
        position: "absolute",
        left: TweetCardSizes.offscreenPosition,
        top: TweetCardSizes.offscreenPosition,
      }}
    >
      <IconButton aria-label="close" sx={{ position: "absolute", top: GlobalSizes.none, right: TweetCardSizes.closeButtonRight }}>
        <img src="X-logo.jpg" alt="X-logo" className="x-logo" />
      </IconButton>
      {alert?.twitter_data?.includes.users && <TweetCardHeader twitterUserType={alert?.twitter_data?.includes.users[0]} />}
      {tweetData && <TweetCardContent tweetData={tweetData} />}
    </Card>
  );
});

export default TweetCard;
