import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { GlobalSizes, LoginPageSizes } from "../size";

function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: GlobalSizes.fullScreenHeight,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: GlobalSizes.flex,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            width: GlobalSizes.midSize, // Default width for smaller screens
            height: GlobalSizes.largeHeight,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: GlobalSizes.gap,
            "@media (min-width: 960px)": {
              width: GlobalSizes.halfSize, // 50% width for screens wider than 960px
            },
          }}
        >
          <img src="/logo900.png" alt="Logo" width={LoginPageSizes.logoSize} height={LoginPageSizes.logoSize} />
          <Typography p={{ pb: GlobalSizes.gap }} variant="h6">
            Welcome to Brinker
          </Typography>
          <Typography fontSize={GlobalSizes.smallFontSize} p={GlobalSizes.gap}>
            Please log in to proceed:
          </Typography>
          <Button onClick={() => loginWithRedirect()} variant="contained" color="primary">
            Login
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default LoginPage;
