import { useMemo, useState, useCallback } from "react";
import { Avatar, Tooltip, Stack, Badge, AvatarGroup, ListItemButton, Divider, Fade } from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import { useWsContext } from "../ws-context";
import { dialogListSizes } from "../size";

const MAX_AVATARS = 3;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ConnectedUser = () => {
  const { user } = useAuth0();
  const { dashboardState } = useWsContext();
  const [open, setOpen] = useState<boolean>(false);

  const users = useMemo(() => {
    const usersList: string[] = [];
    if (dashboardState?.web_socket_connections) {
      Object.keys(dashboardState.web_socket_connections).forEach((email) => {
        if (email !== user?.email) {
          usersList.push(email);
        }
      });
    }
    return usersList;
  }, [dashboardState?.web_socket_connections, user?.email]);

  const genItemText = useCallback(
    (email: string) => {
      const name = dashboardState?.team?.usersInfo?.[email]?.name;
      const nickname = dashboardState?.team?.usersInfo?.[email]?.nickname;
      let primaryText = name;
      if (name === email) {
        primaryText = nickname;
      }
      return <ListItemText primary={primaryText} secondary={email} />;
    },
    [dashboardState?.team?.usersInfo]
  );

  const AvatarUsers = () => (
    <AvatarGroup
      max={MAX_AVATARS}
      onClick={() => {
        setOpen(true);
      }}
    >
      {users.map((email) => {
        const pic = dashboardState?.team?.usersInfo?.[email]?.picture;
        return (
          <Tooltip key={email} title={email} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
            <StyledBadge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} variant="dot">
              <Avatar src={pic} />
            </StyledBadge>
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );

  const ListConnectedUsers = () => (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <DialogTitle>Active Team Members</DialogTitle>
      <Divider variant="middle" />
      <List sx={dialogListSizes.dialogListPadding}>
        {users.map((email) => {
          const pic = dashboardState?.team?.usersInfo?.[email]?.picture;
          return (
            <ListItem disableGutters key={email}>
              <ListItemButton>
                <ListItemAvatar>
                  <StyledBadge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} variant="dot">
                    <Avatar src={pic} />
                  </StyledBadge>
                </ListItemAvatar>
                {genItemText(email)}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );

  return (
    <div>
      <Stack direction="row" spacing={5}>
        <AvatarUsers />
        <ListConnectedUsers />
      </Stack>
    </div>
  );
};

export default ConnectedUser;
