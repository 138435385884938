import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import { AiFilterType, SocialIconMap } from "./state";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

export const COVERAGE = [
  "Facebook (profiles, posts)",
  "Instagram (profiles, posts)",
  "X (Twitter, profiles, posts)",
  "LinkedIn (profiles, posts)",
  "Tik Tok",
  "Telegram (public groups)",
];

export const MITIGATIONS = {
  "counter narrative": { promptId: "mitigation_ai_content_analysis", emails: [], mitigationDescription: "AI Content Analysis Mitigation" },
  facebook: {
    promptId: "mitigation_facebook",
    emails: ["abuse@fb.com", "legal@fb.com", "records@fb.com", "ip@fb.com", "press@fb.com"],
    supportedDomains: ["facebook.com", "fb.com"],
    mitigationDescription: "Take down request ",
  },
  instagram: {
    promptId: "mitigation_instagram",
    emails: ["support@instagram.com", "legal@instagram.com"],
    supportedDomains: ["instagram.com", "instagr.am"],
    mitigationDescription: "Take down request ",
  },
  twitter: { promptId: "mitigation_twitter", emails: [], supportedDomains: ["twitter.com", "x.com"], mitigationDescription: "Take down request" },
  linkedin: { promptId: "mitigation_linkedin", emails: [], supportedDomains: ["linkedin.com", "lnkd.in"], mitigationDescription: "Take down request" },
  tiktok: {
    promptId: "mitigation_tiktok",
    emails: ["info@tiktok.com", "legal@tiktok.com"],
    supportedDomains: ["tiktok.com"],
    mitigationDescription: "Take down request",
  },
  telegram: {
    promptId: "mitigation_telegram",
    emails: ["abuse@telegram.org"],
    supportedDomains: ["t.me"],
    mitigationDescription: "Take down request",
  },
  media: { promptId: "mitigation_media", emails: [], mitigationDescription: "Information for publication" },
  legal: { promptId: "mitigation_legal", emails: [], mitigationDescription: "Notification of Harmful Conduct" },
  general: { promptId: "mitigation_general", emails: [], mitigationDescription: "Information for General mitigation" },
};

export const SOCIAL_ICONS: SocialIconMap = {
  "facebook.com": FacebookIcon,
  "twitter.com": TwitterIcon,
  "x.com": TwitterIcon,
  "instagram.com": InstagramIcon,
  "linkedin.com": LinkedInIcon,
  "youtube.com": YouTubeIcon,
  "tiktok.com": MusicNoteIcon,
};

export const labels = {
  home: {
    title: "Dashboard",
    sideBarTitle: "Home",
    subtitle: "Brinker Dashboard",
  },
  monitoring: {
    title: "Monitoring",
    subtitle: "Alerts for asset detection",
    items: "Alerts",
    csv: "CSV file still in processing, try refreshing in a few moments",
    DEFAULT_VIEW: "Default",
  },
  assets: {
    title: "Assets",
    subtitle: "View and manage your assets",
    items: "Assets",
  },
  flagging: {
    title: "Flagging",
    subtitle: "Suspicious content",
    items: "Flags",
  },
  investigation: {
    title: "Investigation",
    subtitle: "AI investigation of flagged content",
  },
  takeAction: {
    title: "Take Action",
    subtitle: "Generated Mitigations",
    items: "Actions",
    mitigation: "Mitigation request still in processing, try refreshing in a few moments",
    overview: "Overview file still in processing, try refreshing in a few moments",
  },
  teamManagement: {
    title: "Create team",
    subtitle: "Manage your team",
  },
  siemIntegration: {
    title: "SIEM Integration",
    subtitle: "See events from brinker directly in your SIEM",
    splunk: [
      "1. Access your Splunk instance and navigate to Settings > Data Inputs.",
      "2. Add a new HTTP Event Collector (HEC) and note down the generated token.",
      "3. Ensure the HEC is enabled and reachable.",
      "4. Write below your HEC token and Splunk endpoint URL.",
    ],
    sentinel: [
      "1. Log in to the Azure portal and navigate to Microsoft Sentinel > Data connectors.",
      "2. Select the API/SDK connector and follow the wizard to set up a new connection.",
      "3. Obtain the API endpoint and authentication keys required for the integration.",
      "4. Write below your token and endpoint URL.",
    ],
  },
  ai: {
    title_beta: "Narratives (Beta)",
    title: "Narratives",
    subtitle: "Manage Narratives and Counter Narratives",
    items: "Narratives",
    single_item: "Narrative",
    success_count: (count: number) => `${count} Narrative(s) found`,
  },
  common: {
    indexing: "Indexing data... try refreshing in a few moments",
  },
};

export const severity: { [key: string]: { value: number; color: string } } = {
  CRITICAL: { value: 4, color: "#ff796f" },
  HIGH: { value: 3, color: "#ffa19a" },
  MEDIUM: { value: 2, color: "#ffab56" },
  LOW: { value: 1, color: "#ffde7c" },
};

export const aiFilterStatus = (aiFilter: AiFilterType) => aiFilter?.status === "failed" && aiFilter.reason;

export const ASSET_TYPES = ["Brand", "Product", "Person", "Term"];

export const engagementCount = (engagement: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  });
  return formatter.format(engagement);
};

export const unixToLocaleDate = (date: number) => {
  if (!date) return null;
  const dateObject = new Date(date);
  return dateObject.toLocaleString();
};

export const similarityThreshold = 0.7;
