import { MentionType } from "../state";
import { Chip, Tooltip } from "@mui/material";
import { severity } from "../labels";
import CheckIcon from "@mui/icons-material/Check";

function SeverityChip({ alert }: { alert: MentionType }) {
  let icon;
  let displaySeverity = alert.severity || "medium";
  let tooltip = "Severity automatically set by the system";
  if (alert.manual_severity) {
    icon = <CheckIcon />;
    displaySeverity = alert.manual_severity;
    tooltip = "Severity manually set by user to train the system";
  }
  displaySeverity = displaySeverity.toUpperCase();
  return (
    <Tooltip title={`${displaySeverity} - ${tooltip}`}>
      <Chip
        icon={icon}
        size="small"
        label={displaySeverity}
        sx={{ backgroundColor: severity[displaySeverity]?.color, color: "black" }}
        onClick={(e) => e.stopPropagation()}
      />
    </Tooltip>
  );
}

export default SeverityChip;
