import { Box, Chip } from "@mui/material";
import { GlobalSizes } from "../../size";

function CustomLegend({ labels }: { labels: { name: string; color: string }[] }) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: GlobalSizes.smallGap }} mb={GlobalSizes.gap} ml={GlobalSizes.gap}>
      {labels.map((item) => (
        <Box key={item.name} sx={{ display: "flex", alignItems: "center" }}>
          <Chip size="small" label={item.name} sx={{ backgroundColor: item.color, color: "black" }} />
        </Box>
      ))}
    </Box>
  );
}

export default CustomLegend;
