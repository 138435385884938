import { TextField, Button, FormControl, Paper, Typography, useTheme, Stack, Autocomplete, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { GlobalSizes } from "../../size";
import { v4 as uuidv4 } from "uuid";
import { MentionType } from "../../state";
import { initialLinkState } from "./TimeLine";
import CheckIcon from "@mui/icons-material/Check";
import { useWsContext } from "../../ws-context";

interface ManualLinkFormProps {
  currentAlert?: MentionType;
  manualLink?: MentionType;
  setManualLink?: React.Dispatch<React.SetStateAction<MentionType>>;
  isEditing?: boolean;
  enableCategory?: boolean;
  isInvestigationChanged?: boolean;
}

function ManualLinkForm({ currentAlert, manualLink, setManualLink, isEditing, enableCategory, isInvestigationChanged }: ManualLinkFormProps) {
  const { updateAlerts, mentionsState, dashboardState, emitNewData, socketRef } = useWsContext();
  const [currentUploading, setCurrentUploading] = useState({
    fileName: "",
    status: "",
  });
  const [isDragging, setIsDragging] = useState(false);
  const [showUploadingStatus, setShowUploadingStatus] = useState(true);
  const [localLink, setLocalLink] = useState<MentionType>(manualLink || initialLinkState);
  const [categories, setCategories] = useState<string[]>([]);
  const [lastLinkAdded, setLastLinkAdded] = useState("");
  const [titles, setTitles] = useState<string[]>([]);

  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalLink((prevLink) => ({ ...prevLink, [name]: value }));
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      setCurrentUploading({ fileName: file.name, status: "loading" });
      uploadImage(file);
    }
  };

  const dragEventsHandler = (event: React.DragEvent<HTMLDivElement>, entering: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(entering);
  };

  const handleForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (localLink.url === currentAlert?.url) {
      alert("Link cannot be identical to his the parent link!");
      return;
    }

    setShowUploadingStatus(false);

    let mention;

    if (!isEditing) {
      setLastLinkAdded(localLink.url);

      setLocalLink((prevLink) => ({
        ...initialLinkState,
        category: prevLink.category,
      }));

      mention = {
        ...localLink,
        category: localLink.category?.trim(),
        customer_id: dashboardState.customer_id,
        parent_mention: currentAlert?.url,
        hidden: true,
        detection_date: Date.now(),
      };

      updateAlerts(mention);
    } else {
      updateAlerts(localLink);
      setManualLink?.(initialLinkState);
      setLocalLink(initialLinkState);
    }

    emitNewData({ action: "updateAlert", mention: isEditing ? localLink : mention });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    console.log("selectedFile : ", selectedFile);

    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setCurrentUploading({ fileName: selectedFile.name, status: "loading" });
      uploadImage(selectedFile);
      e.target.value = "";
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLFormElement>) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        const file = items[i].getAsFile();
        if (file) {
          setCurrentUploading({ fileName: file.name, status: "loading" });
          uploadImage(file);
        }
      }
    }
  };

  const uploadImage = async (file: File) => {
    console.log("uploading image...");
    console.log("file : ", file);
    setShowUploadingStatus(true);
    const fileName = file.name.replace(/[^a-zA-Z0-9]+/g, "_");
    const folderId = uuidv4();
    const imageId = `${folderId}/${fileName}`;
    const imageUrl = `https://research-image.s3.amazonaws.com/${imageId}`;
    console.log("imageUrl : ", imageUrl);
    setLocalLink((prevLink) => ({ ...prevLink, image: imageUrl }));
    emitNewData({ action: "getSignedUrl", imageId });
    const handleWebSocketMessage = async (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.signedUrl && data?.imageId === imageId) {
        const response = await fetch(data.signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
            "Content-Disposition": "inline",
          },
          body: file,
        });
        if (response.ok) {
          console.log("Image uploaded");
          setCurrentUploading((prev) => ({ ...prev, status: "completed" }));
        } else {
          console.error("Image upload failed");
          setCurrentUploading((prev) => ({ ...prev, status: "error" }));
        }
        console.log(response);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  const handleScrolling = () => {
    if (lastLinkAdded) {
      const element = document.getElementById(lastLinkAdded);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const categoryCounts: { [key: string]: number } = {};
    Object.keys(dashboardState.settings?.category_sort || {}).forEach((category) => {
      categoryCounts[category] = 1;
    });

    mentionsState
      .filter((m) => m.parent_mention === currentAlert?.url)
      .forEach((alert) => {
        const category = alert.category;
        if (!category) return;
        categoryCounts[category] = (categoryCounts[category] || 1) + 1;
      });

    const categoryKeys = Object.entries(categoryCounts)
      .sort((a, b) => b[1] - a[1])
      .map((category) => category[0]);
    setCategories(categoryKeys);
  }, [dashboardState.settings?.category_sort, mentionsState, currentAlert?.url]);

  useEffect(() => {
    if (isInvestigationChanged) {
      setLocalLink(initialLinkState);
      setLastLinkAdded("");
    }
  }, [isInvestigationChanged]);

  useEffect(() => {
    const titlesCounts: { [key: string]: number } = {};

    mentionsState.forEach((m) => {
      if (m.parent_mention && m.title) {
        titlesCounts[m.title] = (titlesCounts[m.title] || 0) + 1;
      }
    });

    const titles = Object.entries(titlesCounts)
      .sort(([, countA], [, countB]) => countB - countA)
      .map(([title]) => title);

    setTitles(titles);
  }, [mentionsState]);

  return (
    <form onSubmit={handleForm} onPaste={handlePaste}>
      <Stack direction={"column"} gap={GlobalSizes.gap}>
        {!isEditing && enableCategory && (
          <FormControl fullWidth sx={{ mb: GlobalSizes.gap }}>
            <Autocomplete
              freeSolo
              disablePortal
              id="combo-box-demo"
              options={categories}
              renderOption={(props, option) => (
                <li {...props}>
                  {option}
                  {dashboardState.settings?.category_sort?.[option] ? <CheckIcon sx={{ ml: GlobalSizes.gap }} color="disabled" /> : null}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Category" required />}
              inputValue={localLink.category}
              onInputChange={(event, newValue) => setLocalLink((prevLink) => ({ ...prevLink, category: newValue }))}
            />
          </FormControl>
        )}
        <FormControl fullWidth>
          <TextField required label="Url" variant="outlined" value={localLink.url} name="url" onChange={handleChange} />
        </FormControl>
        <FormControl fullWidth sx={{ mb: GlobalSizes.gap }}>
          <Autocomplete
            freeSolo
            disablePortal
            id="combo-box-title"
            options={titles}
            renderInput={(params) => <TextField {...params} label="Title" />}
            inputValue={localLink.title}
            onInputChange={(event, newValue) => {
              setLocalLink((prevLink) => ({
                ...prevLink,
                title: newValue,
              }));
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Comments" variant="outlined" value={localLink.note} name="note" onChange={handleChange} multiline rows={4} />
        </FormControl>
        <Paper
          variant="outlined"
          onDragOver={(e) => dragEventsHandler(e, true)}
          onDragEnter={(e) => dragEventsHandler(e, true)}
          onDragLeave={(e) => dragEventsHandler(e, false)}
          onDrop={(e) => {
            handleDrop(e);
            dragEventsHandler(e, false);
          }}
          sx={{
            padding: GlobalSizes.mediumGap,
            textAlign: "center",
            backgroundColor: isDragging ? theme.palette.action.hover : "inherit",
          }}
        >
          <Typography marginBottom={GlobalSizes.gap}>Drag & drop, copy-paste an image here, or click to upload</Typography>
          <Button variant="outlined" component="label">
            Upload Image
            <input type="file" hidden onChange={(e) => handleFileChange(e)} accept="image/*" />
          </Button>
          {showUploadingStatus && (
            <Typography marginTop={GlobalSizes.gap} whiteSpace="pre-wrap">
              {currentUploading.status === "loading" && `Uploading:\n${currentUploading.fileName}`}
              {currentUploading.status === "completed" && `File upload complete!\n${currentUploading.fileName}`}
              {currentUploading.status === "error" && `Error uploading, please try again.\n${currentUploading.fileName}`}
            </Typography>
          )}
        </Paper>
        <Stack direction={"column"} gap={GlobalSizes.gap}>
          <Stack direction={"row"} gap={GlobalSizes.gap}>
            <Button type="submit" variant="contained" color="primary">
              {isEditing ? "Save" : "Add"}
            </Button>
            {lastLinkAdded && (
              <Typography sx={{ mt: GlobalSizes.smallGap }}>
                Link added:{" "}
                <Link onClick={() => handleScrolling()} sx={{ cursor: "pointer" }}>
                  {lastLinkAdded}
                </Link>
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

export default ManualLinkForm;
