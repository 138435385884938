import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { FaHeartbeat, FaGavel } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { labels } from "../labels";
import { Troubleshoot } from "@mui/icons-material";
import { Avatar, Divider } from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { GlobalSizes, avatarSize } from "../size";
import SecurityIcon from "@mui/icons-material/Security";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";

function SidePanel(props: { user?: User }) {
  const { logout } = useAuth0();
  const { dashboardState } = useWsContext();
  const { currentAlert } = useClientContext();

  const userTextEmail = props.user!.email;

  const logoutUser = () => {
    localStorage.clear();
    indexedDB.databases().then(databases => {
      databases.forEach(db => {
        if (!db.name) return;
        indexedDB.deleteDatabase(db.name);
        console.log(`Deleted database: ${db.name}`);
      });
    });
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getNavLinkWithQuery = (path: string) => {
    return currentAlert ? `${path}?url=${encodeURIComponent(currentAlert.url)}` : `${path}`;
  };

  return (
    <Sidebar
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#233476",
          height: GlobalSizes.fullSize,
        },
      }}
    >
      <Menu
        menuItemStyles={{
          button: {
            color: "#fff",
            "&:hover": {
              backgroundColor: "#44499c",
              color: "#fff",
            },
            "&.active": {
              backgroundColor: "#6c73f0",
              color: "#fff",
            },
          },
        }}
      >
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/team")}`} />} style={{ margin: 8 }}>
          <p style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
            <span>{dashboardState.team?.teamName || labels.teamManagement.title}</span>
            <span style={{ fontSize: '0.5rem' }}>Switch teams</span>
          </p>
        </MenuItem>
        <Divider />
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/")}`} />} icon={<Avatar variant="square" src={"/logo.png"} sx={{ width: 32, height: 32 }} />}>
          <p className="button_wrapper">
            <span>Brinker</span>
            <span>Dashboard</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/assets")}`} />} icon={<SecurityIcon />}>
          <p className="button_wrapper">
            <span>{labels.assets.title}</span>
            <span>{labels.assets.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/ai")}`} />} icon={<PsychologyAltIcon />}>
          <p className="button_wrapper">
            <span>{labels.ai.title}</span>
            <span style={{ whiteSpace: "normal" }}>{labels.ai.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/monitoring")}`} />} icon={<FaHeartbeat />}>
          <p className="button_wrapper">
            <span>{labels.monitoring.title}</span>
            <span>{labels.monitoring.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/investigation")}`} />} icon={<Troubleshoot />}>
          <p className="button_wrapper">
            <span>{labels.investigation.title}</span>
            <span>{labels.investigation.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/action")}`} />} icon={<FaGavel />}>
          <p className="button_wrapper">
            <span>{labels.takeAction.title}</span>
            <span>{labels.takeAction.subtitle}</span>
          </p>
        </MenuItem>
        <Divider sx={{ my: GlobalSizes.mediumGap }} />
        <MenuItem disabled={true} icon={<Avatar src={props.user?.picture as string} sx={avatarSize.smallerAvatar} />}>
          <p className="button_wrapper">
            <span>{props.user!.name}</span>
            <span>{userTextEmail}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/siem-integration")}`} />} icon={<CrisisAlertIcon />}>
          <p className="button_wrapper">
            <span>{labels.siemIntegration.title}</span>
            <span style={{ whiteSpace: "normal" }}>{labels.siemIntegration.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<div onClick={() => logoutUser()} />} icon={<LogoutIcon />}>
          <p className="button_wrapper">
            <span>Logout</span>
          </p>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default SidePanel;
