import { ChangeEvent, useEffect, useState } from "react";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import {
  Container,
  IconButton,
  InputBase,
  Paper,
  List,
  ListItem,
  Card,
  Typography,
  CardContent,
  Button,
  Stack,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../Components/Loader";
import { TeamType } from "../state";
import { GlobalSizes, TeamSizes, pagesContainerMargins } from "../size";
import { MenuItem } from "@mui/material";
import { useAuth0, User } from "@auth0/auth0-react";
import PersonIcon from "@mui/icons-material/Person";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { setTeam } from "../team-util";
import { useWsContext } from "../ws-context";

function Team() {
  const { user } = useAuth0();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldBeDisabled, setShouldBeDisabled] = useState(true);
  const [initialTeam, setInitialTeam] = useState<TeamType>(undefined);
  const [localTeam, setLocalTeam] = useState<TeamType>(undefined);

  const { dashboardState, updateDashboard, emitNewData, socketRef } = useWsContext();

  const updateTeam = async () => {
    try {
      setIsLoading(true);
      updateDashboard({ team: localTeam });
      emitNewData({ action: "setTeam", team: localTeam });
      setInitialTeam(localTeam);
      setShouldBeDisabled(true);
    } catch (error) {
      console.log("Failed to Update team data on the server.");
    } finally {
      setIsLoading(false);
    }
  };

  const addNewTeam = async () => {
    try {
      setIsLoading(true);
      emitNewData({ action: "newTeam", team: { users: [(user as User).email!] } });
      const handleWebSocketMessage = async (event: MessageEvent) => {
        const { data } = JSON.parse(event.data);
        if (data?.newTeam) {
          if (!data.newTeam.customer_id) {
            console.log("Response does not contain customer_id");
          } else {
            selectTeam(data.newTeam.customer_id);
          }
          socketRef.current?.removeEventListener("message", handleWebSocketMessage);
        }
      };
      socketRef.current?.addEventListener("message", handleWebSocketMessage);
    } catch (error) {
      console.log("Failed to add new team");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = async (email: string) => {
    if (!email) return;
    if (!localTeam?.users?.includes(email)) {
      setLocalTeam({
        ...localTeam,
        users: [...(localTeam?.users || []), email],
      });
    }
    setEmail("");
  };

  const handleDeleteUser = (emailToDelete: string) => {
    setLocalTeam({
      ...localTeam,
      users: localTeam?.users?.filter((email) => email !== emailToDelete),
    });
  };

  const handleTeamNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalTeam({
      ...localTeam,
      teamName: e.target.value,
    });
  };

  useEffect(() => {
    if (dashboardState.team) {
      setLocalTeam(dashboardState.team);
      setInitialTeam(dashboardState.team);
    } else {
      setLocalTeam({ teamName: "", users: [] });
    }
  }, [dashboardState.team]);

  useEffect(() => {
    if (initialTeam) {
      setShouldBeDisabled(JSON.stringify(initialTeam) === JSON.stringify(localTeam));
    } else {
      setShouldBeDisabled(!localTeam?.teamName);
    }
  }, [localTeam, initialTeam]);

  const selectTeam = (chosenTeamId: string) => {
    if (socketRef.current) {
      emitNewData({ action: "disconnect" });
    }
    console.log({ chosenTeamId });
    setTeam(chosenTeamId);
    window.location.replace(`/?team=${chosenTeamId}`);
  };

  if (!user) return null;
  function normalize(customer: { customerId: string; team: TeamType }) {
    return customer.team?.teamName || customer.customerId;
  }

  return (
    <>
      <Bar label={labels.teamManagement.title + " | " + labels.teamManagement.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <p>Allow other team users access to your dashboard</p>
        <Stack direction="row" width={GlobalSizes.midSize} alignItems="center">
          <FormControl sx={{ mt: GlobalSizes.gap, mb: GlobalSizes.gap, width: GlobalSizes.halfSize }}>
            <InputLabel id="team-select-label">Switch team</InputLabel>
            <Select
              labelId="team-select-label"
              id="team-select"
              value=""
              sx={{ height: TeamSizes.selectHeight, borderTopRightRadius: GlobalSizes.none, borderBottomRightRadius: GlobalSizes.none }}
              onChange={(e) => selectTeam(e.target.value as string)}
            >
              <MenuItem key={user.email} value={user.email}>
                {<PersonIcon />}&nbsp;{user.email}
              </MenuItem>
              {dashboardState?.accessibleTeams &&
                dashboardState.accessibleTeams
                  .sort((t1, t2) => {
                    return normalize(t1).localeCompare(normalize(t2));
                  })
                  .map((accessibleTeam) => (
                    <MenuItem key={accessibleTeam.customerId} value={accessibleTeam.customerId}>
                      {accessibleTeam.customerId === dashboardState.customer_id ? (
                        <CheckBoxIcon color="primary" fontSize="small" />
                      ) : (
                        <CheckBoxOutlineBlankIcon fontSize="small" color="disabled" />
                      )}
                      &nbsp;
                      {normalize(accessibleTeam)}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ height: TeamSizes.selectHeight, borderTopLeftRadius: GlobalSizes.none, borderBottomLeftRadius: GlobalSizes.none }}
            disabled={isLoading || (dashboardState.customer_id !== user.email && !localTeam?.teamName)}
            onClick={() => addNewTeam()}
          >
            Create New Team
          </Button>
        </Stack>
        <Card sx={{ width: GlobalSizes.halfSize, my: GlobalSizes.gap }}>
          <CardContent>
            Team name:
            <Paper
              component="form"
              sx={{
                mt: GlobalSizes.gap,
                width: GlobalSizes.halfSize,
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              {" "}
              <InputBase
                sx={{
                  border: `${GlobalSizes.borderWidth} solid ${!localTeam?.teamName ? "#ff0033" : ""}`,
                  borderRadius: TeamSizes.borderRadius,
                  p: GlobalSizes.gap,
                  width: GlobalSizes.fullSize,
                }}
                placeholder={"Add team name"}
                inputProps={{ "aria-label": "User's email" }}
                value={localTeam?.teamName || ""}
                onChange={(e) => {
                  handleTeamNameChange(e);
                }}
              />
            </Paper>
            <Typography component="div" sx={{ mt: GlobalSizes.gap }}>
              Team ID: {dashboardState.customer_id}
            </Typography>
          </CardContent>
        </Card>
        <Loader />
        <Typography component="div" sx={{ mt: GlobalSizes.gap }}>
          Team members:
        </Typography>
        <List dense sx={{ maxWidth: GlobalSizes.halfSize, mt: GlobalSizes.gap }}>
          {localTeam?.users?.map((email, i) => (
            <Card key={i} sx={{ mb: GlobalSizes.gap, display: "flex" }}>
              <ListItem sx={{ p: GlobalSizes.gap, justifyContent: "space-between" }}>
                <Typography variant="h6" component="div">
                  {email}
                </Typography>{" "}
                <IconButton aria-label="delete" onClick={() => handleDeleteUser(email)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            </Card>
          ))}
        </List>
        <Paper
          component="form"
          sx={{
            px: GlobalSizes.gap,
            display: "flex",
            alignItems: "center",
            width: GlobalSizes.halfSize,
            mb: GlobalSizes.gap,
            mt: GlobalSizes.gap,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleAddUser(email);
          }}
        >
          <PersonIcon />
          <InputBase
            sx={{ ml: GlobalSizes.gap, flex: GlobalSizes.flex }}
            placeholder="User email"
            type="email"
            inputProps={{ "aria-label": "User's email" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" sx={{ p: GlobalSizes.buttonPadding }} aria-label="add">
            Add
          </Button>
        </Paper>
        <Stack direction="row" spacing={GlobalSizes.gap} sx={{ mt: GlobalSizes.gap }}>
          <Button
            variant="contained"
            disabled={!localTeam?.teamName || isLoading || shouldBeDisabled}
            onClick={() => {
              updateTeam();
            }}
          >
            Save Team
          </Button>
          {isLoading && <CircularProgress />}
        </Stack>
      </Container>
    </>
  );
}

export default Team;
