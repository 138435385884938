import { GridInitialState } from "@mui/x-data-grid-pro";
import { createContext, useContext, useState, ReactNode, useEffect, useRef } from "react";
import { useWsContext } from "./ws-context";
import { MentionType } from "./state";

interface ClientContextType {
  currentAlert?: MentionType;
  setCurrentAlert: (alert?: MentionType) => void;
  sidebarAlert?: MentionType;
  setSidebarAlert: (alert?: MentionType) => void;
  tempView: GridInitialState;
  setTempView: (view: GridInitialState) => void;
  stringToColor: (str: string) => string;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const useClientContext = (): ClientContextType => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientContextProvider");
  }
  return context;
};

export const ClientContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentAlert, setCurrentAlert] = useState<MentionType>();
  const [sidebarAlert, setSidebarAlert] = useState<MentionType>();
  const [tempView, setTempView] = useState<GridInitialState>({});
  const { dashboardState, mentionsState } = useWsContext();
  const [narrativeColorMap, setNarrativeColorMap] = useState<Record<string, string>>({});
  const previousTagsRef = useRef<string[]>([]);

  useEffect(() => {
    const aiQuestionsTags = dashboardState?.ai_questions?.map((q) => q.label) || [];
    const monitoringTags = Array.from(
      new Set(mentionsState.flatMap((mention) => mention.ai_filter?.results?.map((result) => result.key) || []).filter(Boolean))
    );
    const combinedTags = Array.from(new Set([...aiQuestionsTags, ...monitoringTags])).sort((a, b) => a.localeCompare(b));
    if (JSON.stringify(previousTagsRef.current) === JSON.stringify(aiQuestionsTags)) return;
    const newColorMap: Record<string, string> = {};
    const lightnessVariants = [60, 65, 70, 75, 80, 85];
    const hueStep = 360 / combinedTags.length;
    combinedTags.forEach((tag, index) => {
      const hue = Math.round(index * hueStep) % 360;
      const lightness = lightnessVariants[index % lightnessVariants.length];
      newColorMap[tag] = `hsl(${hue}, 75%, ${lightness}%)`;
    });
    setNarrativeColorMap(newColorMap);
    previousTagsRef.current = aiQuestionsTags;
  }, [dashboardState.ai_questions, mentionsState]);

  const stringToColor = (str: string): string => {
    return narrativeColorMap[str] || "#808080";
  };

  return (
    <ClientContext.Provider
      value={{
        currentAlert,
        setCurrentAlert,
        sidebarAlert,
        setSidebarAlert,
        tempView,
        setTempView,
        stringToColor,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
