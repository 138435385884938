import { CircularProgress, Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useWsContext } from "../ws-context";

function Loader() {
  const { isLoading } = useAuth0();
  const { dashboardState } = useWsContext();

  return (
    <div>
      {isLoading || !dashboardState.customer_id ? (
        <Stack>
          <CircularProgress />
        </Stack>
      ) : null}
    </div>
  );
}
export default Loader;
