import FlagIcon from "@mui/icons-material/Flag";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import ArchiveIcon from '@mui/icons-material/Archive';
import {
    Button,
    ButtonGroup,
    Tooltip
} from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useClientContext } from "../client-context";
import { GlobalSizes } from "../size";
import { MentionType } from "../state";
import { useWsContext } from "../ws-context";
import { labels } from "../labels";

export default function StatusButtons({ alert }: { alert?: MentionType }) {
    const { updateAlerts, dashboardState, emitNewData } = useWsContext();
    const { setCurrentAlert, setSidebarAlert } = useClientContext();
    const nav = useNavigate();
    const updateAlertRequest = useCallback(
        async (mentionToUpdate: MentionType) => {
            const mention = {
                ...mentionToUpdate,
                customer_id: dashboardState.customer_id,
            };

            emitNewData({ action: "updateAlert", mention });
        },
        [dashboardState.customer_id, emitNewData]
    );
    if (!alert) return null;

    return (
        <ButtonGroup sx={{ mt: GlobalSizes.smallGap }} color="inherit" size="small" variant="contained" aria-label="Basic button group">
            <Tooltip title={alert.hidden ? "Unarchive" : "Archive"} placement="top">
                <Button
                    color="inherit"
                    onClick={() => {
                        updateAlertRequest({ url: alert.url, hidden: !alert.hidden });
                        updateAlerts({ ...alert, hidden: !alert.hidden });
                        alert.hidden = !alert.hidden;
                    }}
                    aria-label="Archive Alert"
                    startIcon={alert.hidden ? <ArchiveIcon /> : <ArchiveIcon color="disabled" />}
                >

                    {alert.hidden ? "Archived" : "Archive"}
                </Button>
            </Tooltip>
            <Tooltip title={alert.flagged ? "Unflag" : "Flag"} placement="top">
                <Button
                    color={alert.flagged ? "error" : "inherit"}
                    onClick={() => {
                        updateAlertRequest({ url: alert.url, flagged: !alert.flagged, flag_date: Date.now() });
                        updateAlerts({ ...alert, flagged: !alert.flagged, flag_date: Date.now() });
                        alert.flagged = !alert.flagged;
                    }}
                    aria-label="Flag Alert"
                    startIcon={<FlagIcon color={!alert.flagged ? "disabled" : "action"} />}
                >
                    {alert.flagged ? "Flagged" : "Flag"}
                </Button>
            </Tooltip>
            <Tooltip title={'View full investigation'} placement="top">
                <Button
                    color={alert.ai_filter?.status === 'success' ? "primary" : "inherit"}
                    onClick={() => {
                        setCurrentAlert(alert);
                        nav(`/investigation?url=${encodeURIComponent(alert.url)}`);
                        setSidebarAlert();
                    }}
                    aria-label="Investigation"
                    startIcon={<TroubleshootIcon />}
                >
                    {labels.investigation.title}
                </Button>
            </Tooltip>
        </ButtonGroup>
    );
}
