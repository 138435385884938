import { Email, HourglassTop, LocationOn, Phone, School, Work } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import JSZip from "jszip";
import { useRef, useState } from "react";
import { FaGavel } from "react-icons/fa";
import AlertSelect from "../Components/AlertSelect";
import Bar from "../Components/Bar";
import LinkButton from "../Components/LinkButton";
import Loader from "../Components/Loader";
import { SimilarAlerts } from "../Components/SimilarAlerts";
import ContactDetails from "../Components/investigation/ContactDetails";
import GeneralView from "../Components/investigation/GeneralView";
import ManualLinkForm from "../Components/investigation/ManualLinkForm";
import TimeLine from "../Components/investigation/TimeLine";
import TwitterData from "../Components/investigation/TwitterData";
import AiFilterView from "../Components/investigation/ai-filter/AiFilterView";
import TweetCard from "../Components/investigation/tweet-screenshot/TweetCard";
import { useClientContext } from "../client-context";
import { labels } from "../labels";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { useWsContext } from "../ws-context";
import MentionFocus from "../Components/MentionFocus";


const removeDuplicates = (array: string[]) => {
  return array.filter((item, index) => array.indexOf(item) === index);
}

function Investigation() {
  const { mentionsState, updateAlerts, emitNewData } = useWsContext();
  const { currentAlert } = useClientContext();
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const cardRef = useRef<HTMLDivElement>(null);

  const twitterUser = currentAlert?.twitter_data?.includes.users[0];

  const saveCustomTitle = async () => {
    console.log("saveCustomTitle", newTitle);
    if (!currentAlert) return;

    const { url, customer_id } = currentAlert;
    updateAlerts({ ...currentAlert, title: newTitle });
    setIsTitleChanged(false);
    emitNewData({ action: "updateAlert", mention: { url, customer_id, title: newTitle } });
    setNewTitle("");
  };

  const downloadZip = async () => {
    const childAlerts = mentionsState.filter((m) => m.parent_mention === currentAlert?.url);
    if (!childAlerts?.length) return;
    const zip = new JSZip();
    const images: { [key: string]: string[] } = {};
    for (const alert of childAlerts) {
      if (!alert.image || !alert.category) continue;
      const { category, image } = alert;
      images[category] = images[category] || [];
      images[category].push(image);
    }

    if (Object.keys(images).length === 0) return;

    try {
      for (const [category, imageUrls] of Object.entries(images)) {
        for (const imageUrl of imageUrls) {
          const imageName = imageUrl.split("/").pop();
          const imageBlob = await fetchImage(imageUrl);
          if (imageBlob) {
            zip.file(`${category}/${imageName}`, imageBlob, { binary: true });
          }
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = `images.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading zip file", error);
    }
  };


  const fetchImage = async (url: string) => {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Failed to fetch image from ${url}`);
    return await response.blob();
  };

  //!JSX
  return (
    <div>
      <Bar label={labels.investigation.title + " | " + labels.investigation.subtitle} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          ...pagesContainerMargins,
          minWidth: GlobalSizes.fullSize
        }}
      >
        <AlertSelect destination="investigation" />
        {!!alert ? (
          <>
            <FormControl fullWidth variant="standard">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  saveCustomTitle();
                }}
              >
                <Input
                  fullWidth
                  key={`${currentAlert?.title}_${currentAlert?.url}`}
                  sx={{ fontSize: GlobalSizes.title }}
                  defaultValue={currentAlert?.title}
                  placeholder={currentAlert?.user || currentAlert?.url}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Save" color="primary" onClick={() => saveCustomTitle()}>
                        {isTitleChanged ? <Check /> : null}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) => {
                    setIsTitleChanged(!!event.target.value);
                    setNewTitle(event.target.value);
                  }}
                />
              </form>
            </FormControl>
            {mentionsState.filter((m) => currentAlert?.url && m.parent_mention === currentAlert?.url).length > 0 && (
              <Typography variant="h6" pb={1} color="primary" fontWeight="bold" sx={{ display: "flex", alignItems: "center" }}>
                <HourglassTop fontSize="small" />
                Investigation in progress...
              </Typography>
            )}
            {twitterUser ? (
              <>
                <TwitterData twitterUser={twitterUser} alert={currentAlert} cardRef={cardRef} />
                <TweetCard alert={currentAlert} ref={cardRef} />
              </>
            ) : (
              <GeneralView alert={currentAlert} />
            )}
            {currentAlert?.url && <LinkButton destination={`/action?url=${encodeURIComponent(currentAlert.url)}`} label={labels.takeAction.title} icon={<FaGavel />} />}

            {currentAlert && (currentAlert.last_time_70_enrich || currentAlert.last_time_60_enrich) && (
              <Grid container spacing={GlobalSizes.mediumGap}>
                {<ContactDetails items={currentAlert?.emails} Icon={Email} title="Email addresses" />}
                {<ContactDetails items={currentAlert?.phone_numbers} Icon={Phone} title="Phone numbers" />}
                {currentAlert?.education && (
                  <ContactDetails items={currentAlert?.education?.map((edu) => edu?.display).filter(Boolean) as string[]} Icon={School} title="Education" />
                )}
                {<ContactDetails items={removeDuplicates(currentAlert?.location_history || [] as string[])} Icon={LocationOn} title="Location history" />}
                {<ContactDetails items={currentAlert?.work_history?.map((work) => work?.display).filter(Boolean) as string[]} Icon={Work} title="Work history" />}
              </Grid>
            )}
            <TimeLine alert={currentAlert} />
            <AiFilterView alert={currentAlert} />
            <SimilarAlerts alert={currentAlert} allMentions={mentionsState} />
            <Divider sx={{ width: GlobalSizes.fullSize, mb: GlobalSizes.mediumGap }} />
            <Typography variant="h5" sx={{ my: GlobalSizes.mediumGap }}>
              Add Links
            </Typography>
            <ManualLinkForm currentAlert={currentAlert} enableCategory={true} />
            <Box mt={GlobalSizes.gap}>
              <Button type="button" variant="contained" color="primary" onClick={downloadZip}>
                Download Images
              </Button>
            </Box>
          </>
        ) : (
          currentAlert && (
            <Typography color="primary" variant="h6">
              No investigations found, start by flagging an alert in the monitoring section...
            </Typography>
          )
        )}
        <Loader />
      </Container>
      <MentionFocus />
    </div>
  );
}

export default Investigation;
