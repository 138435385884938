import { Typography, Box, Divider } from "@mui/material";
import { labels, severity } from "../../labels";
import { Link } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { MentionType } from "../../state";
import { GlobalSizes } from "../../size";

export function UsageStats({ allMentions, filteredMentions }: { allMentions: MentionType[], filteredMentions: MentionType[] }) {
    const flaggedAlerts = useMemo(() => allMentions.filter((m) => m.flagged), [allMentions]);
    const linksNotCollectedManually = useMemo(() => flaggedAlerts.filter((mention) => mention.collected_manually), [flaggedAlerts]);
    const investigationsInProgress = useMemo(() => {
        const investigations = new Set<string>();
        allMentions
            .filter((m) => m.parent_mention)
            .forEach((alert) => {
                if (!alert.parent_mention) return;
                investigations.add(alert.parent_mention);
            });
        return Array.from(investigations);
    }, [allMentions]);

    const activeMentions = useMemo(() => allMentions.filter((mention) => !mention.hidden), [allMentions]);

    const sortedSeverities = useMemo(() => {
        const severityCount: { [key: string]: number } = {};
        activeMentions.forEach((mention) => {
            const severityLevel = mention?.severity?.toLowerCase();
            if (severityLevel) severityCount[severityLevel] = (severityCount[severityLevel] || 0) + 1;
        });

        return Object.entries(severityCount).sort((a, b) => (severity[b[0].toUpperCase()]?.value || 0) - (severity[a[0].toUpperCase()]?.value || 0));
    }, [activeMentions]);

    const alertsFromLastDay = useCallback(() => {
        const last24Hours = new Date().getTime() - 24 * 60 * 60 * 1000;
        const mentionsFromLast24Hours = activeMentions?.filter((mention) => new Date(mention.detection_date || 0).getTime() > last24Hours);
        return mentionsFromLast24Hours;
    }, [activeMentions]);

    return <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box sx={{ p: GlobalSizes.gap, marginBottom: GlobalSizes.mediumGap }}>
        <Typography variant="h6" gutterBottom>
                <Link to="/monitoring">{labels.monitoring.title}</Link>
            </Typography>
            <Typography variant="body1">
                Showing {filteredMentions.length} of {allMentions.length} Total {labels.monitoring.items}
            </Typography>
            <Typography variant="body1">
                {sortedSeverities.map(([severity, count], i) => (
                    <span key={severity}>
                        {severity}: {count}
                        {i !== sortedSeverities.length - 1 ? ", " : ""}
                    </span>
                ))}
            </Typography>
            <Typography variant="body1">
                Last 24 hours: {alertsFromLastDay()?.length}, Flagged: {flaggedAlerts.length}
            </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ p: GlobalSizes.gap, marginBottom: GlobalSizes.mediumGap }}>
            <Typography variant="h6" gutterBottom>
                <Link to="/monitoring"> {labels.flagging.title} </Link>
            </Typography>
            <Typography variant="body1">
                Total {labels.flagging.items}: {flaggedAlerts.length}
            </Typography>
            <Typography variant="body1">
                {labels.flagging.items} collected manually: {linksNotCollectedManually?.length}
            </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ p: GlobalSizes.gap, marginBottom: GlobalSizes.mediumGap }}>
            <Typography variant="h6" gutterBottom>
                <Link to="investigation">{labels.investigation.title}</Link>
            </Typography>
            <Typography variant="body1">
                Total {labels.investigation.title}s: {flaggedAlerts.length}
            </Typography>
            <Typography variant="body1">
                {labels.investigation.title}s in progress: {investigationsInProgress.length}
            </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ p: GlobalSizes.gap, marginBottom: GlobalSizes.mediumGap }}>
            <Typography variant="h6" gutterBottom>
                <Link to="/action">{labels.takeAction.title}</Link>
            </Typography>
            <Typography variant="body1">
                Total {labels.takeAction.items}: {flaggedAlerts.length}
            </Typography>
        </Box>
    </Box>;
}