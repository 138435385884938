const CHOSEN_TEAM_ID = "CHOSEN_TEAM_ID";
const CHOSEN_VIEW = "CHOSEN_VIEW";

const getTeam = () => {
  return localStorage.getItem(CHOSEN_TEAM_ID);
};

const setTeam = (team: string) => {
  localStorage.setItem(CHOSEN_TEAM_ID, team);
};

const getViews = () => {
  try {
    return JSON.parse(localStorage.getItem(CHOSEN_VIEW) || "{}");
  } catch (error) {
    localStorage.removeItem(CHOSEN_VIEW);
    return {};
  }
};

const setViews = (views: { [key: string]: string }) => {
  localStorage.setItem(CHOSEN_VIEW, JSON.stringify(views));
};

export { getTeam, setTeam, getViews, setViews };
